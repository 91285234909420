@import "./var";
@import "./tool";

.page{
  display: flex;
  flex-direction: column;
  header,footer{
    flex-shrink: 0;
  }
  main{
    flex-grow: 1;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
  }
}

.text-purple{
  color: @purple !important;
}

.text-red{
  color: @red !important;
}

.text-blue{
  color: @blue !important;
}

.text-yellow{
  color: @yellow !important;
}

.btn{
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
  background-color: @yellow;
  color: #fff;
  padding: 0 20px;

  .btn-color(@bg-color,@border-color: @bg-color,@color: @bg-color){
    background: @bg-color;
    color: #fff;
    border: 1px solid @border-color;
    &.btn-border{
      color: @color;
      background: #fff;
      border: 1px solid @color;
      &:hover{
        background-color: @border-color;
        color: #fff;
      }
    }
  }

  &.btn-blue{
    .btn-color(@blue);
  }

  &.btn-yellow{
    .btn-color(@yellow);
  }

  &.btn-red{
    .btn-color(@red);
  }

  &.btn-orange{
    .btn-color(#ef8007);
  }

  &.btn-white{
    .btn-color(#fff);
  }

  &.btn-transparent{
    .btn-color(transparent, #fff, #fff);
  }

  &.btn-gray{
    .btn-color(#ededed, #ededed, @blue);
    color: @blue;
  }

  &.btn-square{
    border-radius: 0 !important;
  }

  &.btn-radius{
    border-radius: 25px !important;
  }

  &.btn-square-radius{
    border-radius: 4px !important;
  }

  &.btn-block{
    display: flex;
    width: 100%;
  }

  &[disabled]{
    opacity: .6;
    pointer-events: none;
  }



}

input,select, textarea{
  background-color: #f5f5f5;
  border-radius: 4px;
  border: 1px solid #ededed;
  color: @light-black;
}

textarea{
  border-radius: 4px;
  resize: none;
  width: 100%;
  font-size: 12px;
}

.title-left-line{
  display: flex;
  align-items: center;
  font-size: 20px;
  &:before{
    content: '';
    display: inline-block;
    margin-right: 10px;
    width: 5px;
    height: 20px;
    background-color: @blue;
  }
}

.tag{
  background-color: @yellow;
  color: #fff;
  opacity: .9;
  display: inline-flex;
  align-items: center;
  &.left-top{
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
  }
}

div.msgBoxBackGround {
  top: 0;
  left: 0;
  position: fixed;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  background-color: #000000;
  opacity: 0.6;
  z-index: 1000;
}
div.msgBox {
  box-sizing: border-box;
  padding: 15px;
  position: fixed;
  z-index: 1050;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 11px rgba(0, 0, 0, 0.3);
  .msgBoxTitle {
    width: 100%;
    border-bottom: 1px solid #eaeaea;
    color: #333;
    font-size: 18px;
  }
  .msgBoxImage {
    display: none;
  }
  .msgBoxContent {
    color: #333;
    font-size: 14px;
    line-height: 1.6;
    span{
      color: #333;
    }
  }

  div.msgBoxButtons {
    display: flex;
    justify-content: center;
  }
}

body.over-hidden{
  overflow: unset !important;
}
body{
  display: flex;
}

.summary-list {
  > li {

    .summary-list__title {
      color: @light-black;
    }

    .summary-list__desc {
      color: @red;
    }

    & + li {
    }
  }

  &.summary-list-reverse {
    > li {

      .summary-list__title {
      }

      .summary-list__desc {
      }
    }
  }
}


input[type=text]{
  height: 40px;
  background-color: #f5f5f5;
  padding-left: 5px;
  padding-right: 5px;
}

.select-wrapper{
  display: inline-flex;
  height: 40px;
  position: relative;
  background-color: #f5f5f5;
  border: 1px solid #ededed;
  border-radius: 4px;
  & + &,& + .search-group{
    margin-left: 20px;
  }
  select{
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 0;
    display: block;
    height: 100%;
    padding-right: 45px;
    padding-left: 15px;
  }
  &:after{
    content: "";
    position: absolute;
    right: 14px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
  }
}

.search-group{
  display: inline-flex;
  align-items: center;
  input[type=text]{
    width: 290px;
    border-radius: 4px 0 0 4px;
  }
  .btn{
    width: 76px;
    height: 40px;
    .btn.btn-blue;
    font-size: 14px;
    border-radius: 0 4px 4px 0;
  }
}

.top-menu {
  border-bottom: 1px solid @border;
  display: flex;
  justify-content: space-between;
  > .title{
    font-size: 18px;
    color: #19191a;
    padding-bottom: 9px;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 5px;
      background-color: @blue;
    }
  }
  .tab-menu{
    display: flex;
    > .title {
      font-size: 18px;
      color: #19191a;
      padding-bottom: 14px;
      position: relative;
      &.active{
        &:after {
          content: '';
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 5px;
          background-color: @blue;
        }
      }
      & + .title{
        margin-left: 70px;
      }
    }
  }
}

.data-panel-list{
  color: #fff;
  .number{
    font-size: 30px;
    margin-bottom: 5px;
  }
  .desc{
    font-size: 16px;
  }
}

.tab-wrapper{
  .tab-content-list{
    .tab-content-item{
      display: none;
      &.active{

      }
    }
  }
}


.bg-gray{
  background-color: @bg-color;
}

.mfp-bottom-bar{
  .mfp-title{
    padding-right: 0;
    font-weight: normal;
    line-height: 1.5;
  }
  .mfp-counter{
    display: none;
  }
}

.video-js .vjs-progress-control .vjs-mouse-display{
  white-space: nowrap;
}
