.bg(@src,@imgSuffix: png,@pos: center,@size: cover){
  background: url('@{image_url}@{src}.@{imgSuffix}') no-repeat @pos;
  background-size: @size;
}

.mb40{
  margin-bottom: 40px !important;
}
.flex-center{
	display: flex;
	align-items: center;
	justify-content: center;
}
.flex {
	display: flex;
}

.basis-xs {
	flex-basis: 20%;
}

.basis-sm {
	flex-basis: 40%;
}

.basis-df {
	flex-basis: 50%;
}

.basis-lg {
	flex-basis: 60%;
}

.basis-xl {
	flex-basis: 80%;
}

.flex-sub {
	flex: 1;
}

.flex-twice {
	flex: 2;
}

.flex-treble {
	flex: 3;
}

.flex-direction {
	flex-direction: column;
}

.flex-wrap {
	flex-wrap: wrap;
}

.align-start {
	align-items: flex-start;
}

.align-end {
	align-items: flex-end;
}

.align-center {
	align-items: center;
}

.align-stretch {
	align-items: stretch;
}

.self-start {
	align-self: flex-start;
}

.self-center {
	align-self: center;
}

.self-end {
	align-self: flex-end;
}

.self-stretch {
	align-self: stretch;
}

.align-stretch {
	align-items: stretch;
}

.justify-start {
	justify-content: flex-start;
}

.justify-end {
	justify-content: flex-end;
}

.justify-center {
	justify-content: center;
}

.justify-between {
	justify-content: space-between;
}

.justify-around {
	justify-content: space-around;
}

.title-desc-item{
	font-size: 14px;
}

.not-margin{
	margin: 0;
}

.fz14{
	font-size: 14px !important;
}

.mt10{
	margin-top: 10px !important;
}

.mb10{
	margin-bottom: 10px !important;
}

.mb50{
	margin-bottom: 50px !important;
}

.mb30{
	margin-bottom: 30px !important;
}

.red-point-mark{
	position: relative;
	&:after{
		content: '';
		position: absolute;
		right: -7px;
		top: 2px;
		background-color: @red;
		width: 5px;
		height: 5px;
		border-radius: 50%;
	}
}
