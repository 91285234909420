html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
  word-break: break-all;
  box-sizing: border-box;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1.2;
}
ol,
ul,
li {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
body {
  font: 16px/1.4286 arial, "Hiragino Sans GB", "Microsoft YaHei", "WenQuanYi Micro Hei", sans-serif;
  color: #333;
  background-color: #fff;
}
a {
  text-decoration: none;
  cursor: pointer;
}
p {
  line-height: 1.5;
}
img {
  max-width: 100%;
  max-height: 100%;
  height: auto;
  vertical-align: middle;
}
b {
  font-weight: bold;
}
i {
  font-style: italic;
}
/* 普通文章样式 */
.article-content {
  padding-top: 10px;
  overflow: hidden;
}
.article-content table {
  max-width: 100%;
  table-layout: fixed;
  margin-left: auto;
  margin-right: auto;
}
.article-content table td,
.article-content table th {
  word-break: break-all;
  border: 1px solid #ededed;
  width: auto;
  padding: 5px 10px;
  vertical-align: middle;
}
.article-content iframe {
  width: 100%;
}
.article-content span,
.article-content i {
  display: inline;
}
.article-content strong,
.article-content b {
  font-weight: bold;
}
.article-content i,
.article-content em {
  font-style: italic;
}
.article-content ol,
.article-content ul,
.article-content li {
  list-style: inherit;
}
.article-content img {
  max-width: 100%!important;
  height: auto!important;
}
.article-content p,
.article-content pre {
  font-size: 14px;
  color: #333;
  line-height: 1.5;
  margin-bottom: 20px;
  text-align: left;
}
.article-content p:last-child {
  margin-bottom: 0;
}
.article-content h1 {
  font-size: 20px;
}
.article-content h2 {
  font-size: 18px;
}
.article-content h3 {
  font-size: 16px;
}
.article-content h4,
.article-content h5,
.article-content h6 {
  font-size: 14px;
}
.article-content h1,
.article-content h2,
.article-content h3,
.article-content h4,
.article-content h5,
.article-content h6 {
  padding: 15px 0;
  font-weight: bold;
}
.article-content ul {
  list-style: square;
  margin-left: 1em;
  padding: 10px 0;
}
.article-content a {
  color: #0b76ac;
  text-decoration: underline;
}
.article-content ol {
  list-style: decimal;
  margin-left: 1.5em;
  padding: 10px 0;
}
.article-content blockquote {
  padding-left: 10px;
  border-left: 3px solid #dbdbdb;
  font-size: 14px;
}
.article-content blockquote p {
  font-size: 14px;
}
* {
  -webkit-text-size-adjust: none;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  outline: none;
}
html {
  min-height: 100%;
  height: 100%;
}
html body {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
}
html body .page {
  flex-grow: 1;
}
input,
button,
textarea,
select {
  outline: none;
  border: 0;
}
input:focus,
button:focus,
textarea:focus,
select:focus {
  outline: none;
}
a {
  color: unset;
}
button {
  border: 0;
}
.page {
  display: flex;
  flex-direction: column;
}
.page header,
.page footer {
  flex-shrink: 0;
}
.page main {
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
}
.text-purple {
  color: #a50082 !important;
}
.text-red {
  color: #f20013 !important;
}
.text-blue {
  color: blue !important;
}
.text-yellow {
  color: #ebb532 !important;
}
.btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
  background-color: #ebb532;
  color: #fff;
  padding: 0 20px;
}
.btn.btn-blue {
  background: blue;
  color: #fff;
  border: 1px solid blue;
}
.btn.btn-blue.btn-border {
  color: blue;
  background: #fff;
  border: 1px solid blue;
}
.btn.btn-blue.btn-border:hover {
  background-color: blue;
  color: #fff;
}
.btn.btn-yellow {
  background: #ebb532;
  color: #fff;
  border: 1px solid #ebb532;
}
.btn.btn-yellow.btn-border {
  color: #ebb532;
  background: #fff;
  border: 1px solid #ebb532;
}
.btn.btn-yellow.btn-border:hover {
  background-color: #ebb532;
  color: #fff;
}
.btn.btn-red {
  background: #f20013;
  color: #fff;
  border: 1px solid #f20013;
}
.btn.btn-red.btn-border {
  color: #f20013;
  background: #fff;
  border: 1px solid #f20013;
}
.btn.btn-red.btn-border:hover {
  background-color: #f20013;
  color: #fff;
}
.btn.btn-orange {
  background: #ef8007;
  color: #fff;
  border: 1px solid #ef8007;
}
.btn.btn-orange.btn-border {
  color: #ef8007;
  background: #fff;
  border: 1px solid #ef8007;
}
.btn.btn-orange.btn-border:hover {
  background-color: #ef8007;
  color: #fff;
}
.btn.btn-white {
  background: #fff;
  color: #fff;
  border: 1px solid #fff;
}
.btn.btn-white.btn-border {
  color: #fff;
  background: #fff;
  border: 1px solid #fff;
}
.btn.btn-white.btn-border:hover {
  background-color: #fff;
  color: #fff;
}
.btn.btn-transparent {
  background: transparent;
  color: #fff;
  border: 1px solid #fff;
}
.btn.btn-transparent.btn-border {
  color: #fff;
  background: #fff;
  border: 1px solid #fff;
}
.btn.btn-transparent.btn-border:hover {
  background-color: #fff;
  color: #fff;
}
.btn.btn-gray {
  background: #ededed;
  color: #fff;
  border: 1px solid #ededed;
  color: blue;
}
.btn.btn-gray.btn-border {
  color: blue;
  background: #fff;
  border: 1px solid blue;
}
.btn.btn-gray.btn-border:hover {
  background-color: #ededed;
  color: #fff;
}
.btn.btn-square {
  border-radius: 0 !important;
}
.btn.btn-radius {
  border-radius: 25px !important;
}
.btn.btn-square-radius {
  border-radius: 4px !important;
}
.btn.btn-block {
  display: flex;
  width: 100%;
}
.btn[disabled] {
  opacity: 0.6;
  pointer-events: none;
}
input,
select,
textarea {
  background-color: #f5f5f5;
  border-radius: 4px;
  border: 1px solid #ededed;
  color: #666;
}
textarea {
  border-radius: 4px;
  resize: none;
  width: 100%;
  font-size: 12px;
}
.title-left-line {
  display: flex;
  align-items: center;
  font-size: 20px;
}
.title-left-line:before {
  content: '';
  display: inline-block;
  margin-right: 10px;
  width: 5px;
  height: 20px;
  background-color: blue;
}
.tag {
  background-color: #ebb532;
  color: #fff;
  opacity: 0.9;
  display: inline-flex;
  align-items: center;
}
.tag.left-top {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}
div.msgBoxBackGround {
  top: 0;
  left: 0;
  position: fixed;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  background-color: #000000;
  opacity: 0.6;
  z-index: 1000;
}
div.msgBox {
  box-sizing: border-box;
  padding: 15px;
  position: fixed;
  z-index: 1050;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 11px rgba(0, 0, 0, 0.3);
}
div.msgBox .msgBoxTitle {
  width: 100%;
  border-bottom: 1px solid #eaeaea;
  color: #333;
  font-size: 18px;
}
div.msgBox .msgBoxImage {
  display: none;
}
div.msgBox .msgBoxContent {
  color: #333;
  font-size: 14px;
  line-height: 1.6;
}
div.msgBox .msgBoxContent span {
  color: #333;
}
div.msgBox div.msgBoxButtons {
  display: flex;
  justify-content: center;
}
body.over-hidden {
  overflow: unset !important;
}
body {
  display: flex;
}
.summary-list > li .summary-list__title {
  color: #666;
}
.summary-list > li .summary-list__desc {
  color: #f20013;
}
input[type=text] {
  height: 40px;
  background-color: #f5f5f5;
  padding-left: 5px;
  padding-right: 5px;
}
.select-wrapper {
  display: inline-flex;
  height: 40px;
  position: relative;
  background-color: #f5f5f5;
  border: 1px solid #ededed;
  border-radius: 4px;
}
.select-wrapper + .select-wrapper,
.select-wrapper + .search-group {
  margin-left: 20px;
}
.select-wrapper select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 0;
  display: block;
  height: 100%;
  padding-right: 45px;
  padding-left: 15px;
}
.select-wrapper:after {
  content: "";
  position: absolute;
  right: 14px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}
.search-group {
  display: inline-flex;
  align-items: center;
}
.search-group input[type=text] {
  width: 290px;
  border-radius: 4px 0 0 4px;
}
.search-group .btn {
  width: 76px;
  height: 40px;
  background: blue;
  color: #fff;
  border: 1px solid blue;
  font-size: 14px;
  border-radius: 0 4px 4px 0;
}
.search-group .btn.btn-border {
  color: blue;
  background: #fff;
  border: 1px solid blue;
}
.search-group .btn.btn-border:hover {
  background-color: blue;
  color: #fff;
}
.top-menu {
  border-bottom: 1px solid #ededed;
  display: flex;
  justify-content: space-between;
}
.top-menu > .title {
  font-size: 18px;
  color: #19191a;
  padding-bottom: 9px;
  position: relative;
}
.top-menu > .title:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;
  background-color: blue;
}
.top-menu .tab-menu {
  display: flex;
}
.top-menu .tab-menu > .title {
  font-size: 18px;
  color: #19191a;
  padding-bottom: 14px;
  position: relative;
}
.top-menu .tab-menu > .title.active:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 5px;
  background-color: blue;
}
.top-menu .tab-menu > .title + .title {
  margin-left: 70px;
}
.data-panel-list {
  color: #fff;
}
.data-panel-list .number {
  font-size: 30px;
  margin-bottom: 5px;
}
.data-panel-list .desc {
  font-size: 16px;
}
.tab-wrapper .tab-content-list .tab-content-item {
  display: none;
}
.bg-gray {
  background-color: #f7f7f7;
}
.mfp-bottom-bar .mfp-title {
  padding-right: 0;
  font-weight: normal;
  line-height: 1.5;
}
.mfp-bottom-bar .mfp-counter {
  display: none;
}
.video-js .vjs-progress-control .vjs-mouse-display {
  white-space: nowrap;
}
.flex {
  display: flex;
}
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.block {
  display: block !important;
}
.inline-block {
  display: inline-block !important;
}
.inline {
  display: inline !important;
}
.text-gray {
  color: #999 !important;
}
.text-light-black {
  color: #666 !important;
}
.text-black {
  color: #333 !important;
}
.text-center {
  text-align: center !important;
}
.text-right {
  text-align: right !important;
}
.over-hidden {
  overflow: hidden !important;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
.margin-center {
  margin-left: auto;
  margin-right: auto;
}
.text-overflow {
  overflow: hidden !important;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.text-limit2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  word-break: break-all;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}
.text-limit3 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  word-break: break-all;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}
.text-limit4 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  word-break: break-all;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}
.text-limit5 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  word-break: break-all;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}
.icon-desc-wrapper {
  display: flex;
  align-items: flex-start;
  font-size: 14px;
  color: #999;
}
.icon-desc-wrapper .icon {
  width: 30px;
  display: inline-flex;
  align-items: center;
  flex-shrink: 0;
}
.hidden {
  display: none !important;
}
pre[wrap] {
  word-break: break-word;
}
.visibility-hidden {
  visibility: hidden !important;
}
.margin-center {
  margin-left: auto;
  margin-right: auto;
}
strong {
  font-weight: bold;
}
em {
  font-style: italic;
}
.triangle {
  width: 0;
  height: 0;
  border-top: 50px solid black;
  border-right: 50px solid red;
  border-bottom: 50px solid green;
  border-left: 50px solid blue;
}
.triangle-left {
  width: 0;
  height: 0;
  border-top: 50px solid black;
  border-right: 50px solid red;
  border-bottom: 50px solid green;
  border-left: 50px solid blue;
  border-top: 50px solid transparent;
  border-right: 50px solid transparent;
  border-bottom: 50px solid transparent;
}
.triangle-right {
  width: 0;
  height: 0;
  border-top: 50px solid black;
  border-right: 50px solid red;
  border-bottom: 50px solid green;
  border-left: 50px solid blue;
  border-top: 50px solid transparent;
  border-left: 50px solid transparent;
  border-bottom: 50px solid transparent;
}
.triangle-top {
  width: 0;
  height: 0;
  border-top: 50px solid black;
  border-right: 50px solid red;
  border-bottom: 50px solid green;
  border-left: 50px solid blue;
  border-right: 50px solid transparent;
  border-left: 50px solid transparent;
  border-bottom: 50px solid transparent;
}
.triangle-bottom {
  width: 0;
  height: 0;
  border-top: 50px solid black;
  border-right: 50px solid red;
  border-bottom: 50px solid green;
  border-left: 50px solid blue;
  border-top: 50px solid transparent;
  border-left: 50px solid transparent;
  border-right: 50px solid transparent;
}
.mask-wrapper {
  position: relative;
}
.mask-wrapper:before {
  content: '';
  opacity: 0;
  transition: all 0.3s;
  background-color: rgba(51, 51, 51, 0.4);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.mask-wrapper:hover:before {
  opacity: 1;
}
.input-unit {
  position: relative;
}
.input-unit .unit {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #333;
}
.checkbox-agree {
  display: flex;
  align-items: center;
  font-size: 0.28rem;
}
.checkbox-agree label {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}
.checkbox-agree__desc {
  margin-left: 0.1rem;
  color: #333;
}
.checkbox-agree__show-agree {
  margin-left: 0.1rem;
  color: blue;
  border-bottom: 1px dashed blue;
}
.summary-list {
  display: flex;
  align-items: center;
}
.summary-list > li {
  flex-grow: 1;
  flex-shrink: 0;
  display: inline-flex;
  flex-direction: column;
}
.summary-list > li .summary-list__title {
  line-height: 1.2;
  margin-bottom: 0.15rem;
}
.summary-list > li .summary-list__desc {
  line-height: 1.2;
}
.summary-list > li + li {
  border-left: 1px solid #ededed;
}
.summary-list.summary-list_no-border-left > li + li {
  border-left: 0;
}
.summary-list.summary-list-reverse > li {
  flex-direction: column-reverse;
}
.summary-list.summary-list-reverse > li .summary-list__title {
  margin-bottom: 0;
}
.summary-list.summary-list-reverse > li .summary-list__desc {
  margin-bottom: 0.15rem;
}
.title-desc-item {
  line-height: 1.5;
  display: flex;
}
.title-desc-item__title {
  flex-shrink: 0;
  color: #666;
}
.title-desc-item__desc {
  color: #333;
}
.title-desc-item.title-desc-item__column {
  display: block;
}
.posfixed-clone {
  position: fixed;
  left: 15px !important;
  right: 15px;
  top: 0;
  transition: all 0.3s;
  box-sizing: border-box;
  background-color: #fff;
  z-index: 3;
  margin-top: 0 !important;
  transform: translateY(-110%);
}
.posfixed-clone.active {
  transform: translateY(0);
}
button[type=submit]:disabled {
  opacity: 0.6;
  cursor: default !important;
}
.cursor-pointer {
  cursor: pointer;
}
input:focus,
select:focus,
textarea:focus,
input:hover,
select:hover,
textarea:hover {
  outline: none;
}
.mt0 {
  margin-top: 0 !important;
}
.mb0 {
  margin-bottom: 0!important;
}
.pb0 {
  padding-bottom: 0!important;
}
.icon-swiper-prev {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAoCAQAAAC7+nluAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAACxIAAAsSAdLdfvwAAAAHdElNRQflAw8KMgacGj6FAAABSklEQVQ4y5XVwUobURTG8ZNYFRTiCxRBKIK0KBUXhYKI0NKiG9+om+LCRRdduRC7KEJLFt0IBjeFomCfQRAqFKpSAhIlq5+LISQxmcydO4s758wf7txzvvvdEAnPjIblNPAMv4vBmhOce1oETvuFC7NiNDjlJ/6YE6PRKce49CyL88FJR/hrvpPJAycc4p+Fbi4P/IErL3qzw8An6rix1J8fBMd8w38vH38ZBL+iaWVwtf6wah9Nr4btoDeo2MOt18Or0gvuomU1r9Jd8DNa1vO713n5hHtvRykim3bQ9n60ykL4iLbNIuWGD2jbKgJFNSqROoRttG0U/0B3W+9S0KxYd96koJ0WrBWjJRpbSi6ZWr+kiTCDD9KkncHf0w5MCOPquLZYjHYP9/NitIRlhBJGFErYWwYnmmYoYcUh1JymGXwocW1kcMPyA50SmOOPmEbPAAAAAElFTkSuQmCC) no-repeat center;
  width: 21px;
  height: 40px;
  background-origin: content-box;
  background-size: contain;
  display: inline-block;
}
.icon-swiper-next {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAoCAQAAAC7+nluAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAACxIAAAsSAdLdfvwAAAAHdElNRQflAw8KMgacGj6FAAABSklEQVQ4y5XVwUobURTG8ZNYFRTiCxRBKIK0KBUXhYKI0NKiG9+om+LCRRdduRC7KEJLFt0IBjeFomCfQRAqFKpSAhIlq5+LISQxmcydO4s758wf7txzvvvdEAnPjIblNPAMv4vBmhOce1oETvuFC7NiNDjlJ/6YE6PRKce49CyL88FJR/hrvpPJAycc4p+Fbi4P/IErL3qzw8An6rix1J8fBMd8w38vH38ZBL+iaWVwtf6wah9Nr4btoDeo2MOt18Or0gvuomU1r9Jd8DNa1vO713n5hHtvRykim3bQ9n60ykL4iLbNIuWGD2jbKgJFNSqROoRttG0U/0B3W+9S0KxYd96koJ0WrBWjJRpbSi6ZWr+kiTCDD9KkncHf0w5MCOPquLZYjHYP9/NitIRlhBJGFErYWwYnmmYoYcUh1JymGXwocW1kcMPyA50SmOOPmEbPAAAAAElFTkSuQmCC) no-repeat center;
  width: 21px;
  height: 40px;
  background-origin: content-box;
  background-size: contain;
  display: inline-block;
  transform: rotateZ(180deg);
}
.icon-search-main {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAMAAADzapwJAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAABL1BMVEWlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIIAAACsMMpeAAAAY3RSTlMAAUeY1O323bdtEkDN8noEfP3bKBxRssFx/HkGPOPIClIYmwLWJvP+NEwFgKehbhb1B9gpzvcJrqRT5rs9tE/wFWmtVBQ31VrcxdMhlT7G9IQaCFfZzxOln7lE0C1jMz8RycT875huAAAAAWJLR0Rkwtq4CQAAAAlwSFlzAAALEgAACxIB0t1+/AAAAAd0SU1FB+UDDBAaG6FFdb4AAAEGSURBVBjTbdHnUsJAFAXgo7GhaNwoakCjWIjEgigWbESJDQt27O28/zu4SSAmGe+Pu3e+mT07cxdwq61d6ejs6u5J9CJUfUk2q39ADXRQUEsMqepwaoQcbV0Yo57ONOfxCRqTfoKuT/3lZTVOu+fMLNPhd+Zy5rw88tSsMGOBi7IvcTmiKOgrRWCVa1FGiTJlnRsx3mQK0EVMscUysM1CjHe4C+xxP6pWxbSBAx5GuUpDdicnjsKaOeaJtyie2iFWWPKWaJ2xdh4EX5CX/mhfsX594063d/dy5w+PvhcVQTPZeHquk7WX18Dx1qi4fyOMdxWOdKcVqn7ky59f3ijdwD/lfP/8AqZoLgTBsgaqAAAAAElFTkSuQmCC) no-repeat center;
  width: 22px;
  height: 22px;
  background-origin: content-box;
  background-size: contain;
  display: inline-block;
}
.icon-search-main {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAMAAADzapwJAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAABL1BMVEWlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIIAAACsMMpeAAAAY3RSTlMAAUeY1O323bdtEkDN8noEfP3bKBxRssFx/HkGPOPIClIYmwLWJvP+NEwFgKehbhb1B9gpzvcJrqRT5rs9tE/wFWmtVBQ31VrcxdMhlT7G9IQaCFfZzxOln7lE0C1jMz8RycT875huAAAAAWJLR0Rkwtq4CQAAAAlwSFlzAAALEgAACxIB0t1+/AAAAAd0SU1FB+UDDBAaG6FFdb4AAAEGSURBVBjTbdHnUsJAFAXgo7GhaNwoakCjWIjEgigWbESJDQt27O28/zu4SSAmGe+Pu3e+mT07cxdwq61d6ejs6u5J9CJUfUk2q39ADXRQUEsMqepwaoQcbV0Yo57ONOfxCRqTfoKuT/3lZTVOu+fMLNPhd+Zy5rw88tSsMGOBi7IvcTmiKOgrRWCVa1FGiTJlnRsx3mQK0EVMscUysM1CjHe4C+xxP6pWxbSBAx5GuUpDdicnjsKaOeaJtyie2iFWWPKWaJ2xdh4EX5CX/mhfsX594063d/dy5w+PvhcVQTPZeHquk7WX18Dx1qi4fyOMdxWOdKcVqn7ky59f3ijdwD/lfP/8AqZoLgTBsgaqAAAAAElFTkSuQmCC) no-repeat center;
  width: 22px;
  height: 22px;
  background-origin: content-box;
  background-size: contain;
  display: inline-block;
}
.icon-news-activity {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAeCAMAAAB61OwbAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAABDlBMVEUAAAAzMzMzMzMzMzMzMzMzMzMzMzM0NDMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzM1NTQ9OzY6ODU0NDPFp2DauGbZt2ZsYESGdUymjlY3Nzd/f39ZWVk/Pz////+ZmZng4ODMzMzp6emhoaE2Nja+vr7j4+NhYWFgYGDf399JSUljWkJnXENaUj9tbW3IyMjFxcXQsGOwl1llZWXl5eXZ2dn4+Pjy8vJFRUV3aUh8bUlpXkRpaWnR0dE1NTXNzc2IiIirq6vb29vY2Nj+/v7ExMSysrLQ0ND19fX5+fmWlpbPz8+kpKRAQEBGRkbV1dXc3Nytra11dXXx8fHi4uJ2dnYAAACfOwDHAAAAEnRSTlMAD6n73U2r+yZzgPDgiBDx/fW64kvVAAAAAWJLR0QAiAUdSAAAAAlwSFlzAAALEgAACxIB0t1+/AAAAAd0SU1FB+UDDwk2IV868LMAAAEKSURBVCjPldNpU8IwEIDhShWPKuAKiiiQcIhXveUoKBQPRMD7+v+/xGSnmTHtZgafr31ndps21kzMBtLsnIXisJamZGB+AQN7fSNL2czBIgaQztK2YGnKILNNy6nALAjyBVpRBYzTSn+DcgVVTcFOMLS2y/f2i9LBoQicZQEDF46OhRM45WdBey4ChAGDC/nml1DnvNGUGnLESiKRjAb6Dk40aHlSSw/a0LkSrqHLezWc7Pe14Ead3S2/c5nk3mvB4GGIHrlhB/NJqmDkhYz1YBL5kPknLXh+YSGvb//bwetXQt71oBv9m5pa8FEP+/yafgfb/yafD37kxRFBHHxG8NUylvHyplYd6RccDGb+FFYI1AAAAABJRU5ErkJggg==) no-repeat center;
  width: 32px;
  height: 30px;
  background-origin: content-box;
  background-size: contain;
  display: inline-block;
}
.icon-welfare-project {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAMAAAAM7l6QAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAArlBMVEUAAAAzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzNYUD5nXUM8OjaahVPauGbLrGFMRzuchlNxZEbOr2JBPjdxZUZKRToAAADGKewTAAAAK3RSTlMABFis5fw82Png0Ff6w0sH/XASUl0zAXn3xCB42wx3T2B6K/tJrcBZbQZbetQ4CQAAAAFiS0dEAIgFHUgAAAAJcEhZcwAACxIAAAsSAdLdfvwAAAAHdElNRQflAw8OJS6rJbo1AAABBklEQVQoz33T6XKCMBSG4QMkssiiuKHFpe76oVK1tfd/ZQ1oWxwPeX+FeSYTJguRyjAtIVFJCss06FHDBhzXq+Q6gN24a9NHEEb0VBQG8JvlXL/VJqZ2y1fzDRusKodtkImAagpgkoWwjkNYJJyojiNHkHT/PuNOt/fkriR4j3F/kKgNGY4q7OGf34DscEQ6ZjlOcMrzjzMmLE+R5aoLxGTM8AzXgj+/gHTE8S0v/XLGsJ7V+kf0NJwf0NVxho6GT0hihr9vRdcMeH/9tfni96Ilgz6zqfPZvWnMHgl/oBpeYqXjNTYa3iLd1fO2vMg8R8s1/L0aIPFeKx5RWj4icEmx2uwK/QHycC7huojF1AAAAABJRU5ErkJggg==) no-repeat center;
  width: 30px;
  height: 30px;
  background-origin: content-box;
  background-size: contain;
  display: inline-block;
}
.icon-right-arrow {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAKCAQAAADBEsxyAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAACxIAAAsSAdLdfvwAAAAHdElNRQflAxANJxElSQwaAAAAZklEQVQY032OsQmAMBQFL0GwsnQTFxAXULCyFBsncCjB1i4LuIqdlSA8i2CVxPvNwfHgI4LrVX1uCSlx1F5jGQp2unSGnJURjEQKsVj+MEQ+nyVJjyaRJXY3AxuJfNHiIJ5PGg6vL8SyLvXsYPCUAAAAAElFTkSuQmCC) no-repeat center;
  width: 15px;
  height: 10px;
  background-origin: content-box;
  background-size: contain;
  display: inline-block;
}
.icon-heart-gray {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAOCAQAAACMJlQBAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAACxIAAAsSAdLdfvwAAAAHdElNRQflAxAQGhlVhPy1AAABA0lEQVQY023QPyiEARgH4Of7XCnZkIHCZlAmxYXFYrtIKbEYr07+bQw2hTKdK12ijExKYlDiLna6lMHCQCKlGO4M7jjy2973+fUObxCnxoIB9S6t2sK4CW0e7Zj3HFHrzKs5t3qltAolLJrUJOFcZxBP6tLlHUSdCPTIgEpZpxGDZopMxjFF5t2KpYgaD36yLyyb7tRF5EQdfa9WlafbVShpSrP/0mjaWhAP7WkQ9faHq5y4FwvljaiyIfhTSKk2Kh/iRUy/2V88JSbmhYoOeHRp3YWbIvfZNCxLqcC1wIpdT2hxYFn6C4J46WhgR7tZH5blDCl8rX/eUjDmUNq2Y2Ml5hOFP0XST2z2LgAAAABJRU5ErkJggg==) no-repeat center;
  width: 16px;
  height: 14px;
  background-origin: content-box;
  background-size: contain;
  display: inline-block;
}
.icon-heart-white {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAOCAQAAACMJlQBAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAACxIAAAsSAdLdfvwAAAAHdElNRQflAxAQAipoTwX6AAAA9UlEQVQY022PLUtDARSGn12EwUAmTJuggiD+A1mwGARRMK8IwjAtGASDH9Gsm3abZUm0LIpN5kUQRCzCUFEZA5ubPIZt3nvVc8p5v+A9iDnLNmwbuiLiqqEdX6w4JCmHueSDMo/Mssk+ASX2uGCMEoPM4KF10yJi3o5f5nsobd0DfLbQIxBr1mKoYGOAHG9Ec04QQ0+M4I27sUxyt7zGNVuO/yuP2rSIgWeGZv7IGa88NUDMeu+JqV+GY+/MShdM23IjIa/bckr6Blyy7fyPPOeni907yuzYdFLECV/d7vORIWXVB5dd8NZq1CjZ+8h3m1biP30DhnX2ZfszL5oAAAAASUVORK5CYII=) no-repeat center;
  width: 16px;
  height: 14px;
  background-origin: content-box;
  background-size: contain;
  display: inline-block;
}
.icon-call-white {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAQAAAC1+jfqAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAACxIAAAsSAdLdfvwAAAAHdElNRQflAxAQGi+aPmksAAAA8klEQVQoz22RsStEARzHP/devTqFTlncdtNNikkZsNFLuUHJ+gYmf4FBymyxqfMHKMNhu/0NRpJcuRIJKWVw6vKxqHu89/1Nv2+f+n7rixjbteOcFB0u+OaysS/OFAOpiYixT04UAV/Wf58jD/JAwCs1AEKGeSengBYNABIi9oqAfdaoAxEPfOYBxG0vjKx471RRSQxt2xQX7VoTMXA8C+Co1+6Iqz665bQtv72yMQCw6q274qRte55bccmOh0aDtKqXNi1n8kc885g/xok3zmacxLv/rdd99tQVxyy76Ydxfp4hN0zt2Td1XkpSqBIhfYAfJkPxnUf4JvQAAAAASUVORK5CYII=) no-repeat center;
  width: 16px;
  height: 16px;
  background-origin: content-box;
  background-size: contain;
  display: inline-block;
}
.icon-call-gray {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAQAAAC1+jfqAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAACxIAAAsSAdLdfvwAAAAHdElNRQflAxAQAjsC/yUIAAAA/UlEQVQoz23OPyjEcRwG4OfupxsUKZlsJuu58QYZDCJlUFLqdNctZopJCjGwGBSliEIRUjcykLrBqqycovxbLrkwnHPi926fb0/v941k6bakJONUSGp0WJfyYVevizAwY8wRhu1LKPwFUW3OwLGcif8NUQ9aQKDOcxg41AfSYqbDwKIBrYi5UQwD1+ZsiNnUIx4GmPVi2ZOsve89UU0VECT4dGBSk1UFO0reLVvR785VuYEXnQZN2dWl17lAo1HzVsQi2UpXs5y8kV9D620pRn/OW0kNLiV/Xl7tSwSJ6uA32x6taVd0j4wF6eoXldQakhJXI2/cyX9QTkSgBF9WAUEesIxR/wAAAABJRU5ErkJggg==) no-repeat center;
  width: 16px;
  height: 16px;
  background-origin: content-box;
  background-size: contain;
  display: inline-block;
}
.icon-top-arrow-gray {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAICAQAAABaf7ccAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAACxIAAAsSAdLdfvwAAAAHdElNRQflAxAQAwyjWbFGAAAAlElEQVQY013PMQ7BAACF4a+ITcxiYuxqEhEDNomEA9hYbK7hEm5gIBFjU5yhN+hukzQ1tRpvfP+fvLxgq5LAUeAg/1WNCm46Wcp1bHyKslbitpu5qZm5m/a/0BXpGXl5GemJdKtC6CkzlIDEUOYpLISxWGIiLedSE4nYmJqVu4uFd/WOt4Wru3V9sPOwl/lP5qyl/wVzgCMja/uEwgAAAABJRU5ErkJggg==) no-repeat center;
  width: 16px;
  height: 8px;
  background-origin: content-box;
  background-size: contain;
  display: inline-block;
}
.icon-top-arrow-white {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAICAMAAADHqI+lAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAY1BMVEX/+vr/+vr/+vr/+vr/+vr/+vr/+vr/+vr/+vr/+vr/+vr/+vr/+vr/+vr/+vr/+vr/+vr/+vr/+vr/+vr/+vr/+vr/+vr/+vr/+vr/+vr/+vr/+vr/+vr/+vr/+vr/+voAAAArXXrDAAAAIHRSTlMAAY2OBqf29Q++7Dkb0d8nKuHOGD3uug1S96KjU5CIiW2OfB0AAAABYktHRCCzaz2AAAAACXBIWXMAAAsSAAALEgHS3X78AAAAB3RJTUUH5QMQEBo5burcfQAAAFZJREFUCNdNjd0SQCAYRL8o+ldRCfH+b2nSoL05c2ZndgGeoK5H0ASTYST4d8q4EJzR16XSE8CklaxurJsLZ2dN4eJDrE0MfgFY0/at4T0dkM/mD135Bp6cAzO+f34iAAAAAElFTkSuQmCC) no-repeat center;
  width: 16px;
  height: 8px;
  background-origin: content-box;
  background-size: contain;
  display: inline-block;
}
.icon-clock-gray {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAQAAAD8x0bcAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAACxIAAAsSAdLdfvwAAAAHdElNRQflAxEJJAYyAhWnAAABOElEQVQoz33SP0tbYRTH8c+92Ay+hyQULWTSoZDmj7YlqehSXNTOdSjkNfRddGkzFUSp1C5SOhRjUYKaBqqjglCILXkP3iUdcnMTscnvGZ6Hc76ch/PjF9QlqnilIoMbP3x2OGhMxXfOe8+d29NBxoo3jtRcDaFnvvrtqWYy9a1F77S9dEyInH0NpREEmsoa9uUI6oFTKUURKPpgPgFTzkRKU6oKnsQID82NTIvU/FQNbWhpG6e2lo1QZbjqf3WoEkrrTIRupEO3d0oRevG5GJrZlR2BvlkXxO8uyPgb1Lc8Upzw3Znr0I6C/Fgkr2C7b+YDpcSpUcVmhnpem/XJ9D1k2q4Zm3ohrqxacmLhDlJ24oVVl4MUHMv7qOmX7zrIWvZYS74flSAJXWDJmqq0yB9NXxzo9Vv/AK7jU4qjDe6pAAAAAElFTkSuQmCC) no-repeat center;
  width: 18px;
  height: 18px;
  background-origin: content-box;
  background-size: contain;
  display: inline-block;
}
.icon-people-gray {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAQCAQAAABjX+2PAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAACxIAAAsSAdLdfvwAAAAHdElNRQflAxEJJBtRBHl+AAABA0lEQVQY023QPSjEYRzA8c//fwZk85oVA4lJysZCJ6WEhdE5i7pMFoPNYjGdt11SlPIyWUySU4ThJhHLLaZTh+H+nLfv9DzPp56e3xOkRTWZN6DOo31L7ouHZRH22vVmW1aLSRMGnUKQhga3sgY9gUaH6rXKEYIZVcYi5NGoGgk+uce5rFJ3rvSU+N2rn+VVlDijS+03rNfprMRr3m0qj7DcuoINiA1BzoM5I0J1BqzqlnDyfe6MG+1Wot2li+KiePmcM9UW9enQa0Gtc6nPb0lZtmXKy9fTKm0al5QO0s2uHRtW+DFYzI5+baEUkr+QgmmB2VDcgQd/e3YkHsrb9397gg+1zUJV134zAgAAAABJRU5ErkJggg==) no-repeat center;
  width: 15px;
  height: 16px;
  background-origin: content-box;
  background-size: contain;
  display: inline-block;
}
.icon-eye-gray {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAAOCAQAAABnEe8CAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAACxIAAAsSAdLdfvwAAAAHdElNRQflAxIOMiGcaqxwAAABRElEQVQoz03RPUubARQF4OcNhbhIomIRil+VFhSsTopUUFoH0aGDlFIcxIquRXEXXEKpdo8WM/gLBLEhiIuCgosuggZKI92CbdIg4hQH3yY5y+V+cDjnniCpgg6z3nqpwV+XDqT8+r8KwrOYNbOK0s4VxPUZF5OyrAhPQJddT3225a7CXWfOqhGTso9sz5y48U4OcQteyNpQQLsdTYb8DpKBjE5D8mhzqNGFbkWvXaPZsZyxiClvTMuDdbc6DXquZB3kTRs1FSRP/fQh1FOyZBPM+6Y+nG7riYgoV38SWqrWEBEJ7w2GXdqiFrRYlA5nAz76EiQD+9orFo7EnOn3z7BcrYWyGVEZrbj2SkJWQq8cWmVEzSg/ptDlh0YrvruvyImas+pP9b0Q99UnBXvOlNTrNyEuZVmhNtPa6JvcuHJgqxr9AyPdW+7BlXSOAAAAAElFTkSuQmCC) no-repeat center;
  width: 19px;
  height: 14px;
  background-origin: content-box;
  background-size: contain;
  display: inline-block;
}
.icon-prev-left {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAoCAQAAAC7+nluAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAACxIAAAsSAdLdfvwAAAAHdElNRQflAxISAC+Dno8SAAABv0lEQVQ4y43VbWjNURwH8M/+W8Msa4VSIyIlpZSWPE2bzcNtrd1pyVKi1l5IEi945Y1XSpIXnlJ7gZrcrNzhtt0QkVZLKeUhYqVImrhxteaFLrOH/9nv3fmeT6dzfufUKTpnClWhy5GSKcGMapVREM7So9pryRCdKW2Nt2oNxtMyaeu8V+sdUSzsVmNQrTfE0WlSNvmgzqs/wWS0VMpmH9V5UYiiSWCXbT6p8/xfOBEtcUWTz+o9Gx2Pp8Uua/FFvaf/T0TjYKdWQxoMjF0jGjO6qM2QLfrH72s0LXLBbt8kPJ7orNEoeNYeOQkPJ+5f9Bee1i6n0f3J7qRAT9rnh2ZZ4ukJB+QlZYinxx2S1+IW8fSYo/J2uEmIFoVIoYob75quRrMBL0OUPuXW266/8DLjOnDYKaVS6sOUg86Y4YaNYTpiv/PKpG0IUUZ0uKRM2toQZUS7TuXSVocow/a6qsJtq0KUYbtcUyFjZYgyrM11lTJWhCi/7NRttl7LQ5S8Vj3myFoWouQl3TFXn6Uhyk9JvebpsyREyWlyT5WsRSFKTsID82UtCFG+S3hkoayq8F/w1VZPLJYKU4Y0yOj4DYhJbyXLAm0SAAAAAElFTkSuQmCC) no-repeat center;
  width: 21px;
  height: 40px;
  background-origin: content-box;
  background-size: contain;
  display: inline-block;
}
.icon-prev-right {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAoCAQAAAC7+nluAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAACxIAAAsSAdLdfvwAAAAHdElNRQflAxISAC+Dno8SAAABv0lEQVQ4y43VbWjNURwH8M/+W8Msa4VSIyIlpZSWPE2bzcNtrd1pyVKi1l5IEi945Y1XSpIXnlJ7gZrcrNzhtt0QkVZLKeUhYqVImrhxteaFLrOH/9nv3fmeT6dzfufUKTpnClWhy5GSKcGMapVREM7So9pryRCdKW2Nt2oNxtMyaeu8V+sdUSzsVmNQrTfE0WlSNvmgzqs/wWS0VMpmH9V5UYiiSWCXbT6p8/xfOBEtcUWTz+o9Gx2Pp8Uua/FFvaf/T0TjYKdWQxoMjF0jGjO6qM2QLfrH72s0LXLBbt8kPJ7orNEoeNYeOQkPJ+5f9Bee1i6n0f3J7qRAT9rnh2ZZ4ukJB+QlZYinxx2S1+IW8fSYo/J2uEmIFoVIoYob75quRrMBL0OUPuXW266/8DLjOnDYKaVS6sOUg86Y4YaNYTpiv/PKpG0IUUZ0uKRM2toQZUS7TuXSVocow/a6qsJtq0KUYbtcUyFjZYgyrM11lTJWhCi/7NRttl7LQ5S8Vj3myFoWouQl3TFXn6Uhyk9JvebpsyREyWlyT5WsRSFKTsID82UtCFG+S3hkoayq8F/w1VZPLJYKU4Y0yOj4DYhJbyXLAm0SAAAAAElFTkSuQmCC) no-repeat center;
  width: 21px;
  height: 40px;
  background-origin: content-box;
  background-size: contain;
  display: inline-block;
  transform: rotate(180deg);
}
.icon-download-purple {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAANCAMAAABFNRROAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAZlBMVEWlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIKlAIIAAAA12gvIAAAAIHRSTlMAVWnHEcMUzOEBjvxyNI1z7j9AXPJUWkbwpe+9MwfY16lOCqMAAAABYktHRCHEbA0WAAAACXBIWXMAAAsSAAALEgHS3X78AAAAB3RJTUUH5QMTCgAaf6TenAAAAF1JREFUCNeFjEcCgCAQAyPVAoK9ovz/laJYjs4hmzlkASDxJwkihDLGKHmMh+C/JqhML0slFcjyQulgWhVlFq6xVcjamrhr2g5d299fMIzTPOBFL2ssjn04bP5jPwBC1QUr2WJZEwAAAABJRU5ErkJggg==) no-repeat center;
  width: 13px;
  height: 13px;
  background-origin: content-box;
  background-size: contain;
  display: inline-block;
}
.icon-play-white {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAQAAADYBBcfAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAACxIAAAsSAdLdfvwAAAAHdElNRQflAxMOCgR4TaOpAAABU0lEQVQ4y52VPy9DURyGn14dLAgiFV1slVAfAIOFtR1NNglTvwKCjqwWbP6kEoPRQL+B3WDSdmFV0fsYqhJyXfd6z3ZOnrzn996b9yAiTnvog6/G6dUHD53uEogZt+yYVB03zXTBPVVrLjnhcMyacNmaqnuCc4Zq5fPKf6+KGjqHF+plYgzxUj3DplpKBZbUZkAOeCSNHoFcAECYCgwBgl8ORxiNp6PBMk0aHDAYQ6pa/DH+lXqvPrkSEU5RNdoxCyyyQT+n3DCV3PFaHRLHPDa07Y59Px3jQcQF79W1ZFf9rkzyVHsa44Q6BXY5Sj7jsOs+qzcWolLNRjq9A7fM0mCF8zTfsWzbN/cdiPzJYxyvGCfDc9z42V/2X/6KOkiQbSQT0AImU4GTQCugDqymAleBO5z/b1lh9bMel83H1mP+qx6rvULeTlXI271CRpxJ+wR8AI23XItF9G8QAAAAAElFTkSuQmCC) no-repeat center;
  width: 28px;
  height: 28px;
  background-origin: content-box;
  background-size: contain;
  display: inline-block;
}
.icon-co-partner {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACYAAAAgCAMAAABJuvqBAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAABpFBMVEUAAAAzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzPauGbauGbauGYzMzMzMzPauGbauGbauGYzMzPauGYzMzMzMzPauGYzMzMzMzMzMzPauGbauGbauGYzMzMzMzMzMzPauGYzMzMzMzMzMzMzMzPauGZfVkA0NDOJeE4zMzMzMzMzMzMzMzMzMzMzMzPauGarklgAAACzCtb1AAAAiHRSTlMABlms4Pj+6cR8HAxfqNPkw4w7UeH6jgoDde7HNQHiMxAIImG/EcDNczgfKEuV8Pxuj/19CUXm3BXqVBOXclNS2SNrP3uS1QJb5yQP7FCtd4mm4zDfCxkF7x7b9Mp4vbuEGPdAJXsdmwex5CTdX+sladgmISewXD4tPINCF/b7H2H+k0dDZWnanw5QNgAAAAFiS0dEAIgFHUgAAAAJcEhZcwAACxIAAAsSAdLdfvwAAAAHdElNRQflAw8JNiLGM6EJAAABlElEQVQ4y4XR1VbDUBAF0AFKgQDFirs7hQAtbsWLu1Pc3d3hlK/mpg0xUjIPWXPP2kkmGSKhAgKDTMHmkNAwjsQKj4i0RJmiY2JJrjgrxIpPSBSCpOSU3yQ1TUTpCUBGWGZWdk5uHmBhD8wvAAqLiktKy8orYKv0sSrw1TXiLbV2OLg6C+ob0n1BYxPQLDQt4Fvl93MOmNrgaJeTDvBONr0VnYoxBQcrp0y60N1DvbAGkMb19SsD1wAGaQjDRFoXpXIjGKUxjJOBc2KCgEkycFMwMzZNBs4F0AxmScfNKVwp5qkAETqM2C+WXC4WaBFLZOCW4aaVVdT+79bAs6W4YefUYH1jU+HqJrDFTtsLbNsqtuPZ3ZMcu+y7xJPaHXhkZ2/D4ZE0hdodS+4E6DtVTOs487XnF5cKd2VD8LXqq+JvvN2tx3MsuTu1Irp/wKPXPe3K7pnXKLaQVdHtSe775Y/Sca966o9743WVxr36Uyr3/eJX+dyd0Dz/p7zO9k7+51K6j08j5XWsjBRRyYnZ/NWuTX8A0M6axxAkYT8AAAAASUVORK5CYII=) no-repeat center;
  width: 38px;
  height: 32px;
  background-origin: content-box;
  background-size: contain;
  display: inline-block;
}
.icon-close-image,
.icon-close {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAAqCAQAAABvygHQAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAACxIAAAsSAdLdfvwAAAAHdElNRQflAxcQAzb4glBNAAADNklEQVRIx72XP2wTSRSHv90ETETSJFWQneYcWwiBASGFVBCqO+lACCQ6kEAgIhwqmigS2AFBGTogpwunK8+n03URVxxJgQQFwYBAShz+FE6wA+iKCzQg8qPwbuxde3YdQPymGc2b+fzmvbeeGUsEaD39DLCNBN20A+8oUeAxU9zlg3mZZYTuYJBDvOA2MxRY4D2wkSi97GKAOH9xg7xhrRq1lCZV1Ih6hKH1aERFTSrVyFo/tEFjKmlIESPQbRENqaSr2hAGjSuvnLpCgW7r0p/KKx4E7VNZ6aaBbhtSWX0maJ/eaP+akQj9rKVabO3GSzrwRUiEDqhUDUI1PXmd/WIkQmeVd1Pm1ukYUY5Uus9Yi+LVbo4i56rFn+IftvLGA7UI/NiwWfFBO3nCjzwGG4ArXHKRjlr4nUwg8lcu+8b+4yJXAFqBnWzhoG/JbxwFYNSIPA6scN4zPsEwO8jbwBnG+egxrvASgGxDb6vIlz7LR8ZJg6UIZbZRrFqcmI5ywQGPGpCnuOmJKUCMR3Tb7GauFrmqDBcdaNaMrFORefpasico8q833o6msdkD7MVi2oTs9GN/oMcmxX1Mcr3NkG3Cy4pmSLXSyxwEYOECkMEi1gQSZklYekuiuuOaRFXlpoxGyLh/dhdzNh0sE6wMl5yeQrwEWKbDxiJMNlGnZ9ETOhuw+Z+OkBmV9MjxOhtC7GDZZolNTSBXOOmpBLO6WWplniRPQpFuLCuVQAA4SaElu5moofjrkfWfQ33xH2PR5jb7mvSysvmwIAwwZXOP5Gp2w5Dh2BhJ7rVkP5Egxh3P9i0mnIw3qktPEHzbT1Pmbxu4xiDrPCZRBsRJQ6lXvBWvfOPrGOR65Z9/hllOcc1jHkE8D/h6MohX/OIbPcEsM+7Bt51bdQdfk/IdfD/xiNVzf0y5rzr1EfpDY97LRNs3uEw8VNt3uPZ87QXtdeMLWgVb1pk1IwOvkgj1Kq+cOpsGdiqnvBLeUdtXIfP0s8hT0kRCqylCmqcs0k/BZ2n4+ylNakHDihk9jGlYC6aHhPnJs5PTHOYZU9ynwALvgHaiJFafPOM8aLzUaupxlqx5nM2FP84+A2X4R/beYIClAAAAAElFTkSuQmCC) no-repeat center;
  width: 42px;
  height: 42px;
  background-origin: content-box;
  background-size: contain;
  display: inline-block;
}
.icon-map-data {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAXCAQAAACsCtdvAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAACxIAAAsSAdLdfvwAAAAHdElNRQflAxoNLgGGgUdTAAABB0lEQVQoz4WSsWoCQRRFr0saTWNtYLeyttEiNoJF8gWSSr/CfEBSCUm5ha1fIf6DpUhiKyQoJFgJm4gnxbLjG3XNnebNmTMDM/OE3GgxZEFCwoIhrcNKVoSMOc6YyEp1VpzLinomRazJy5oolSZcygSJJv/lNlBXh6BYDTUUC0N7Ymb2DNy1B4bOxNZMK066MXQbqGAO/nbVl6GFQO9meueqe0PfxJM5eEkNIWosDX0WIYkBO6ZM2RmSEAoRX3ylOH3xMp+5ygfl7IMfcqWObZXRWWXk91OJ+Yky59qXRJWNp2yoHnemEG1+nfJD+7R909FjD8CeruW+JB4B6Pv0Sn5eVJT06sM/Bt8xpOPgnEcAAAAASUVORK5CYII=) no-repeat center;
  width: 18px;
  height: 23px;
  background-origin: content-box;
  background-size: contain;
  display: inline-block;
}
.icon-page-prev {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAOCAQAAAC4X5UdAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAACxIAAAsSAdLdfvwAAAAHdElNRQflAx0OHQ3kzeioAAAAY0lEQVQI11XOwQ3CMBAEwInjFmiAFqggrgFaoA07jSWinSBqCI8InNvfrE7aG55OqT458KGkSFuKJP85eXMUzf1HUiTJICSrWPtNRrX36lhpWM+zNLtFsfXXZyzKeOsDLxfXL/gxG2kYkfZrAAAAAElFTkSuQmCC) no-repeat center;
  width: 8px;
  height: 14px;
  background-origin: content-box;
  background-size: contain;
  display: inline-block;
}
.icon-page-next {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAOCAQAAAC4X5UdAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAACxIAAAsSAdLdfvwAAAAHdElNRQflAx0OHQ3kzeioAAAAY0lEQVQI11XOwQ3CMBAEwInjFmiAFqggrgFaoA07jSWinSBqCI8InNvfrE7aG55OqT458KGkSFuKJP85eXMUzf1HUiTJICSrWPtNRrX36lhpWM+zNLtFsfXXZyzKeOsDLxfXL/gxG2kYkfZrAAAAAElFTkSuQmCC) no-repeat center;
  width: 8px;
  height: 14px;
  background-origin: content-box;
  background-size: contain;
  display: inline-block;
  transform: rotate(180deg);
}
.btn.btn-s {
  padding: 0 10px;
  height: 24px;
  font-size: 14px;
  min-width: 80px;
}
.btn.btn-m {
  padding-left: 30px;
  padding-right: 30px;
  height: 45px;
  font-size: 18px;
}
.btn.btn-shadow {
  box-shadow: 0 2px 8px 0 rgba(2, 30, 7, 0.1);
}
.big-container {
  width: 1400px;
  margin: 0 auto;
}
.container {
  width: 1180px;
  margin: 0 auto;
}
.small-container {
  width: 1080px;
  margin: 0 auto;
}
.active-item {
  border-bottom: 2px solid;
  border-image: url(data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEASABIAAD/2wBDAAMCAgMCAgMDAwMEAwMEBQgFBQQEBQoHBwYIDAoMDAsKCwsNDhIQDQ4RDgsLEBYQERMUFRUVDA8XGBYUGBIUFRT/2wBDAQMEBAUEBQkFBQkUDQsNFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBT/wAARCAACAFADAREAAhEBAxEB/8QAGgAAAQUBAAAAAAAAAAAAAAAABgACAwQHBf/EAB4QAAEEAQUAAAAAAAAAAAAAAAABAwUxAgQyM0FC/8QAGwEAAgIDAQAAAAAAAAAAAAAABQYDBwACBAn/xAAcEQEAAgIDAQAAAAAAAAAAAAAAAzEBBAIFQTL/2gAMAwEAAhEDEQA/AMEY6LGkrL0NioVwdoVz3FZQzU0aA8lCd16VNv0fRnHiVDtfeSjsiDSUgH5lbZ9dRqjk5FTYtZxsiyBypDUIlIwJlMd2nTHYRPWX/9k=) 100% 0 30 stretch;
}
textarea {
  padding: 10px;
}
.pagination-box {
  color: #999;
  margin-bottom: 20px;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}
.pagination-box .pagination-btn {
  width: 64px;
  height: 24px;
  color: #808080;
  font-size: 14px;
}
.pagination-box .page-count {
  font-size: 14px;
  color: #666;
}
.pagination-box .page-count > .input {
  width: 34px;
  height: 24px;
  border-radius: 4px;
  border: solid 1px #ededed;
  padding: 0 4px;
  margin-right: 6px;
  margin-left: 9px;
}
.pagination-box .page-count > .input::-webkit-outer-spin-button,
.pagination-box .page-count input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
.pagination-box .page-count > .input[type='number'] {
  -moz-appearance: textfield;
}
.pagination-box .page-count > .confirm {
  margin-left: 12px;
  color: blue;
  font-size: 14px;
  display: inline-block;
  padding: 0 8px;
  border-radius: 4px;
  border: 1px solid #ededed;
}
.pagination-box .n-list .n {
  color: #666;
  font-size: 16px;
  margin-right: 14px;
  border-radius: 50%;
  width: 33px;
  height: 33px;
  display: inline-block;
  text-align: center;
  line-height: 33px;
}
.pagination-box .n-list .n:last-child {
  margin-right: 0;
}
.pagination-box .n-list .n.current,
.pagination-box .n-list .n:hover {
  color: #fff;
  background-color: #ebb532;
}
.popup-wrapper {
  padding: 40px 35px 35px;
}
.popup-wrapper .icon-close {
  right: 30px;
  top: -65px;
}
.popup-wrapper .popup-header {
  padding: 13px 0 10px;
}
.popup-wrapper .popup-header h3.title span {
  height: 43px;
  line-height: 40px;
  border-bottom-color: #ebb532;
}
.popup-wrapper .popup-content {
  padding-left: 0;
  padding-right: 0;
}
.popup-wrapper.popup-wrapper-420 {
  width: 420px;
  padding: 0 20px 70px 20px;
}
.popup-wrapper.popup-wrapper-420 .form-group {
  margin-bottom: 7px;
}
.popup-wrapper.popup-wrapper-420 .form-group .field-name {
  width: 95px;
}
.popup-wrapper.popup-wrapper-420 .form-btn-group {
  margin-top: 40px;
  padding-left: 95px;
}
.popup-wrapper.popup-wrapper-420 .form-btn-group .btn {
  padding: 0;
  width: 80px;
  height: 28px;
  font-size: 14px;
}
.popup-wrapper.popup-wrapper-420 .form-btn-group .btn + .btn {
  margin-left: 50px;
}
#video-popup .popup-wrapper {
  margin: 0;
  padding: 0;
}
#video-popup .popup-content {
  margin: 0;
  padding: 0;
  overflow: visible !important;
}
#video-popup .popup-content .video-js {
  width: 100%;
  text-align: center;
}
#video-popup .popup-content iframe {
  width: 100%;
  height: 500px;
}
div.msgBox .msgBoxTitle {
  font-size: 16px;
  color: #19191a;
  padding-bottom: 10px;
  font-weight: bold;
  position: relative;
}
div.msgBox .msgBoxTitle:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 8%;
  height: 3px;
  background-color: blue;
}
div.msgBox .msgBoxContent {
  margin: 0;
  padding: 20px 0;
  width: 100%;
  text-align: center;
}
div.msgBox .msgBoxButtons input[type='button'] {
  margin-left: 20px;
  height: 34px;
  line-height: 34px;
  width: 120px;
  color: #fff;
  border: 0;
  display: inline-block;
}
div.msgBox .msgBoxButtons input[type='button']:first-child {
  margin-left: 0;
  color: blue;
  background: #fff;
  border: 1px solid blue;
  border-radius: 25px !important;
}
div.msgBox .msgBoxButtons input[type='button']:first-child:hover {
  background-color: blue;
  color: #fff;
}
div.msgBox .msgBoxButtons input[type='button']:last-child {
  background: blue;
  color: #fff;
  border: 1px solid blue;
  border-radius: 25px !important;
}
div.msgBox .msgBoxButtons input[type='button']:last-child.btn-border {
  color: blue;
  background: #fff;
  border: 1px solid blue;
}
div.msgBox .msgBoxButtons input[type='button']:last-child.btn-border:hover {
  background-color: blue;
  color: #fff;
}
.title-bar {
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.title-bar .title-wrapper {
  display: flex;
  align-items: center;
}
.title-bar .icon-wrapper {
  display: flex;
  align-items: center;
}
.title-bar .icon-wrapper .icon {
  width: 35px;
}
.title-bar__title {
  font-size: 22px;
  color: #333;
}
.title-bar__more {
  font-size: 14px;
  color: #999;
}
.tag {
  padding-left: 10px;
  padding-right: 10px;
  height: 34px;
  font-size: 14px;
}
.btn-group.btn2 .btn {
  width: 130px;
}
.btn-group .btn {
  margin-left: 30px;
}
.img-list {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-left: -20px;
  margin-bottom: -15px;
}
.img-list > li {
  cursor: pointer;
  border-radius: 4px;
  margin-bottom: 15px;
  width: 180px;
  height: 116px;
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 0;
  flex-shrink: 0;
  margin-left: 20px;
}
.img-list.auto-size > li {
  width: auto;
  height: auto;
}
.page-panel {
  background-color: #fff;
  border-radius: 4px;
  padding: 20px 20px 60px;
}
.form-block {
  padding-bottom: 20px;
  border-bottom: 1px solid #ededed;
  margin-bottom: 30px;
}
.input-unit .unit {
  font-size: 14px;
  width: 30px;
}
.summary-list > li {
  padding: 0 20px;
}
.summary-list > li:first-child {
  padding-left: 0;
}
.summary-list > li:last-child {
  padding-right: 0;
}
.summary-list > li .summary-list__title {
  font-size: 14px;
}
.summary-list > li .summary-list__desc {
  font-size: 18px;
}
.summary-list > li + li {
  border-left: 1px solid #ededed;
}
.title-block {
  text-align: center;
  margin-bottom: 50px;
}
.title-block__title {
  color: #333;
  font-size: 30px;
}
.title-block__desc {
  margin-top: 4px;
  color: #999;
  font-size: 18px;
}
.form-group {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}
.form-group__multi {
  align-items: flex-start;
}
.form-group .field-name {
  width: 120px;
  flex-shrink: 0;
  font-size: 14px;
  color: #333;
  line-height: 1.5;
  display: flex;
  align-items: baseline;
  justify-content: flex-end;
  padding-right: 15px;
}
.form-group .field-name .sub-field-name {
  font-size: 12px;
  line-height: 1.2;
  color: #999;
  margin-left: 5px;
}
.form-group .form-field {
  flex-grow: 1;
}
.form-group .form-field input[type=text],
.form-group .form-field input[type=password],
.form-group .form-field input[type=number],
.form-group .form-field input[type=email],
.form-group .form-field textarea,
.form-group .form-field select {
  width: 100%;
  background-color: #fafafa;
  border: 1px solid #ededed;
  transition: border-color 0.3s;
}
.form-group .form-field input[type=text]:focus,
.form-group .form-field input[type=password]:focus,
.form-group .form-field input[type=number]:focus,
.form-group .form-field input[type=email]:focus,
.form-group .form-field textarea:focus,
.form-group .form-field select:focus {
  border-color: blue;
}
.form-group .form-field textarea {
  display: block;
  height: 85px;
  padding: 10px;
}
.form-group .form-field select {
  background-color: #fafafa;
}
.form-group .form-field input[type=text],
.form-group .form-field input[type=password],
.form-group .form-field input[type=number],
.form-group .form-field input[type=email],
.form-group .form-field select {
  height: 40px;
  padding: 0 10px;
}
.form-group .form-field p.desc {
  font-size: 14px;
  color: #666;
}
.form-group .form-field .radio-label {
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  color: #333;
  cursor: pointer;
}
.form-group .form-field .radio-label input[type=radio],
.form-group .form-field .radio-label input[type=checkbox] {
  width: 16px;
  margin-right: 5px;
}
.form-group .form-field .radio-label + .radio-label {
  margin-left: 50px;
}
.form-group + .form-msg {
  margin-top: -5px;
  font-size: 14px;
  line-height: 1.2;
  margin-bottom: 20px;
  color: #999;
  padding-left: 120px;
}
.load-more {
  font-size: 14px;
  height: 30px;
  line-height: 30px;
  margin-top: 95px;
  display: block;
  text-align: center;
  color: #a50082;
  cursor: pointer;
}
.no-data {
  font-size: 14px;
  height: 30px;
  line-height: 30px;
  margin-top: 95px;
  display: block;
  text-align: center;
  color: #a50082;
  cursor: pointer;
  color: #999;
  cursor: default;
}
.bread-nav {
  font-size: 14px;
  display: flex;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 30px;
}
.bread-nav__item {
  display: inline-flex;
  align-items: center;
  color: #999;
}
.bread-nav__item .text {
  max-width: 120px;
  overflow: hidden !important;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.bread-nav__item:hover {
  color: #333;
}
.bread-nav__item + .bread-nav__item:before {
  content: '>';
  display: inline-block;
  padding-left: 10px;
  padding-right: 10px;
  cursor: default;
}
.pagination-box {
  color: #999;
  margin-bottom: 20px;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}
.pagination-box .pagination-btn {
  color: #999;
  font-size: 14px;
}
.pagination-box .prev {
  margin-right: 18px;
}
.pagination-box .next {
  margin-left: 18px;
  margin-right: 8px;
}
.pagination-box .page-count {
  font-size: 14px;
}
.pagination-box .page-count > .input {
  width: 34px;
  height: 24px;
  border-radius: 4px;
  border: solid 1px #ededed;
  padding: 0 4px;
  margin-right: 6px;
  margin-left: 9px;
}
.pagination-box .page-count > .input::-webkit-outer-spin-button,
.pagination-box .page-count input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
.pagination-box .page-count > .input[type='number'] {
  -moz-appearance: textfield;
}
.pagination-box .page-count > .confirm {
  margin-left: 12px;
  color: #f20013;
  font-size: 14px;
  display: inline-block;
  padding: 0 8px;
  border-radius: 4px;
  border: 1px solid #ededed;
}
.pagination-box .n-list .n {
  color: #666;
  font-size: 16px;
  margin-right: 14px;
  width: 32px;
  height: 32px;
}
.pagination-box .n-list .n:last-child {
  margin-right: 0;
}
.pagination-box .n-list .n.current {
  color: #fff;
  background-color: blue;
}
.pagination-box .n-list .n:hover {
  color: blue;
}
.content-block {
  margin-top: 120px;
  margin-bottom: 120px;
}
.content-block__header {
  text-align: center;
  margin-bottom: 60px;
}
.content-block__header-icon {
  font-size: 0;
  margin-bottom: 15px;
}
.content-block__header-title {
  font-size: 32px;
  color: #333;
  position: relative;
}
.content-block__header-title:after {
  content: '';
  position: absolute;
  left: 50%;
  bottom: -15px;
  transform: translateX(-17px);
  width: 34px;
  height: 2px;
  background-color: #ebb532;
}
.page {
  padding-top: 80px;
  padding-bottom: 150px;
}
.data-panel-list {
  display: flex;
  align-items: center;
}
.data-panel-list > li .number,
.data-panel-list .item .number {
  font-size: 36px;
  font-weight: bold;
  white-space: nowrap;
}
.data-panel-list > li .desc,
.data-panel-list .item .desc {
  font-size: 18px;
}
.panel {
  padding-left: 30px;
  padding-right: 30px;
  background-color: #fff;
}
.panel + .panel {
  margin-top: 10px;
}
.column-panel {
  display: flex;
  align-items: flex-start;
  margin-top: 20px;
}
.column-panel .column-left {
  flex-shrink: 0;
  width: 850px;
  margin-right: 30px;
}
.column-panel .column-right {
  flex-grow: 1;
}
.title-underline-box {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ededed;
  justify-content: space-between;
}
.title-underline-box .title-underline {
  font-size: 20px;
  padding: 15px 0 10px;
  color: #666;
  height: 58px;
}
.title-underline-box .title-underline.active {
  color: #333;
  border-bottom: 2px solid;
  border-image: url(data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEASABIAAD/2wBDAAMCAgMCAgMDAwMEAwMEBQgFBQQEBQoHBwYIDAoMDAsKCwsNDhIQDQ4RDgsLEBYQERMUFRUVDA8XGBYUGBIUFRT/2wBDAQMEBAUEBQkFBQkUDQsNFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBT/wAARCAACAFADAREAAhEBAxEB/8QAGgAAAQUBAAAAAAAAAAAAAAAABgACAwQHBf/EAB4QAAEEAQUAAAAAAAAAAAAAAAABAwUxAgQyM0FC/8QAGwEAAgIDAQAAAAAAAAAAAAAABQYDBwACBAn/xAAcEQEAAgIDAQAAAAAAAAAAAAAAAzEBBAIFQTL/2gAMAwEAAhEDEQA/AMEY6LGkrL0NioVwdoVz3FZQzU0aA8lCd16VNv0fRnHiVDtfeSjsiDSUgH5lbZ9dRqjk5FTYtZxsiyBypDUIlIwJlMd2nTHYRPWX/9k=) 100% 0 30 stretch;
}
.title-underline-box .title-underline + .title-underline {
  margin-left: 140px;
}
.title-underline-box .more {
  color: #a50082;
}
.title-underline-box.tab-list {
  display: flex;
}
.title-underline-box.tab-list > li {
  display: flex;
}
.title-underline-box.tab-list > li + li {
  margin-left: 140px;
}
.tab-list .tab-item {
  cursor: pointer;
}
.button-tab .tab-list {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 45px;
}
.button-tab .tab-list > li a {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 140px;
  height: 45px;
  border-radius: 4px;
  background-color: #ededed;
  color: #666;
}
.button-tab .tab-list > li.active a {
  background-color: #ebb532;
  color: #fff;
}
.button-tab .tab-list > li + li {
  margin-left: 110px;
}
.activity-list > li:hover a .title {
  color: #a50082;
}
.activity-list > li a {
  display: flex;
  padding: 30px 0;
  border-bottom: 1px solid #ededed;
}
.activity-list > li a .img {
  width: 210px;
  height: 147px;
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 0;
  flex-shrink: 0;
}
.activity-list > li a .content {
  flex-grow: 1;
  margin-left: 25px;
  width: calc(100% - 210px - 147px);
}
.activity-list > li a .img img {
  transition: all 0.3s;
}
.activity-list > li a:hover .img img {
  transform: scale(1.05);
}
.activity-list > li a .content {
  padding-top: 5px;
}
.activity-list > li a .content .title {
  color: #333;
  font-size: 18px;
  margin-bottom: 12px;
  overflow: hidden !important;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.activity-list > li a .content .time {
  color: #999;
  font-size: 14px;
  margin-bottom: 17px;
}
.activity-list > li a .content .desc {
  color: #666;
  font-size: 14px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  word-break: break-all;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}
.sidebar {
  flex-shrink: 0;
  padding-left: 20px;
  padding-right: 20px;
  background-color: #fff;
}
.sidebar__list > li:hover a .content .title {
  color: #a50082;
}
.sidebar__list > li + li {
  border-top: 1px solid #ededed;
}
.sidebar__list > li a {
  display: flex;
  padding: 20px;
  margin-left: -20px;
  margin-right: -20px;
}
.sidebar__list > li a .img {
  width: 100px;
  height: 70px;
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 0;
  flex-shrink: 0;
}
.sidebar__list > li a .content {
  flex-grow: 1;
  margin-left: 15px;
  width: calc(100% - 100px - 70px);
}
.sidebar__list > li a .content {
  max-width: 200px;
}
.sidebar__team-list > li a .content {
  padding-top: 5px;
}
.sidebar__team-list > li a .content .title {
  font-size: 16px;
  color: #333;
  overflow: hidden !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 10px;
}
.sidebar__team-list > li a .content .desc {
  overflow: hidden !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 180px;
  color: #999;
  font-size: 14px;
}
.sidebar__activity-list > li a .content .title {
  font-size: 16px;
  color: #333;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  word-break: break-all;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  line-height: 1.8;
}
.search-form {
  position: relative;
  width: 580px;
  height: 46px;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 2px 8px 0 rgba(2, 30, 7, 0.1);
}
.search-form .search-content input[type=text] {
  font-size: 16px;
  background-color: #fff;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  padding-left: 10px;
  padding-right: 30px;
}
.search-form .search-btn {
  position: absolute;
  right: 0;
  top: 0;
  width: 40px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: transparent;
}
.mfp-counter {
  display: none;
}
.mfp-title {
  padding-right: 0 !important;
}
.article-box {
  padding-top: 35px;
  padding-bottom: 105px;
}
.article-box__title {
  font-size: 30px;
  color: #333;
  text-align: center;
  margin-bottom: 28px;
}
.article-box__meta {
  font-size: 14px;
  padding-bottom: 30px;
  border-bottom: 1px solid #ededed;
  color: #999;
  display: flex;
  align-items: center;
  justify-content: center;
}
.article-box__meta .icon-desc-wrapper + .icon-desc-wrapper {
  margin-left: 125px;
}
.article-box__meta .btn {
  margin-left: 125px;
  height: 28px;
  padding: 0 10px;
  font-size: 14px;
}
.article-box .article-switch {
  margin-top: 100px;
  font-size: 14px;
}
.article-box .article-switch > li + li {
  margin-top: 25px;
}
.article-box .article-switch > li a {
  color: #666;
}
.article-box .article-switch > li a:hover {
  color: #a50082;
}
.news-list {
  margin-left: -50px;
  margin-bottom: -50px;
  display: flex;
  flex-wrap: wrap;
}
.news-list > li {
  width: 360px;
  margin-bottom: 50px;
  margin-left: 50px;
}
.news-list > li .img img {
  transition: all 0.3s;
}
.news-list > li:hover .img img {
  transform: scale(1.05);
}
.news-list > li:hover a .content .title {
  color: #a50082;
}
.news-list > li:hover a .content .desc {
  color: #333;
}
.news-list > li a {
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 8px 0 rgba(2, 30, 7, 0.1);
}
.news-list > li a .img {
  width: 100%;
  height: 254px;
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 0;
  flex-shrink: 0;
  position: relative;
}
.news-list > li a .img .tag {
  position: absolute;
  left: 0;
  top: 0;
  height: 34px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  opacity: 1;
  background-color: rgba(223, 186, 83, 0.84);
}
.news-list > li a .content {
  padding-left: 20px;
  padding-right: 20px;
  height: 172px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.news-list > li a .content .title {
  color: #333;
  font-size: 20px;
  overflow: hidden !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 15px;
}
.news-list > li a .content .time {
  font-size: 14px;
  color: #999;
  margin-bottom: 15px;
}
.news-list > li a .content .desc {
  font-size: 14px;
  color: #666;
  line-height: 1.5;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  word-break: break-all;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}
.news-list.project-list > li a .content {
  height: 75px;
}
.news-list.project-list > li a .content .title {
  margin-bottom: 0;
  overflow: hidden !important;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.news-list.article-list > li a .content {
  height: 110px;
}
.news-list.article-list > li a .content .title {
  margin-bottom: 0;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  word-break: break-all;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  white-space: normal;
}
.news-list.simple-content-two-line-list .content {
  justify-content: flex-start;
  padding-top: 18px;
  line-height: 1.8;
}
.news-list.video-list > li .img {
  position: relative;
}
.news-list.video-list > li .img:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1;
}
.news-list.video-list > li .img .icon {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -14px;
  margin-top: -14px;
  z-index: 2;
}
.tab-wrapper-default .tab-list {
  display: flex;
  align-items: center;
  font-size: 20px;
  color: #333;
  border-bottom: 1px solid #ededed;
}
.tab-wrapper-default .tab-list > li {
  display: inline-flex;
  margin-bottom: -1px;
}
.tab-wrapper-default .tab-list > li a {
  display: inline-flex;
  align-items: center;
  height: 64px;
  color: #333;
}
.tab-wrapper-default .tab-list > li + li {
  margin-left: 100px;
}
.tab-wrapper-default .tab-list > li.active {
  border-bottom: 2px solid;
  border-image: url(data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEASABIAAD/2wBDAAMCAgMCAgMDAwMEAwMEBQgFBQQEBQoHBwYIDAoMDAsKCwsNDhIQDQ4RDgsLEBYQERMUFRUVDA8XGBYUGBIUFRT/2wBDAQMEBAUEBQkFBQkUDQsNFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBT/wAARCAACAFADAREAAhEBAxEB/8QAGgAAAQUBAAAAAAAAAAAAAAAABgACAwQHBf/EAB4QAAEEAQUAAAAAAAAAAAAAAAABAwUxAgQyM0FC/8QAGwEAAgIDAQAAAAAAAAAAAAAABQYDBwACBAn/xAAcEQEAAgIDAQAAAAAAAAAAAAAAAzEBBAIFQTL/2gAMAwEAAhEDEQA/AMEY6LGkrL0NioVwdoVz3FZQzU0aA8lCd16VNv0fRnHiVDtfeSjsiDSUgH5lbZ9dRqjk5FTYtZxsiyBypDUIlIwJlMd2nTHYRPWX/9k=) 100% 0 30 stretch;
}
.tab-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ededed;
}
.tab-header .tab-list {
  border-bottom: 0;
}
.tab-header .right .btn {
  padding: 0 20px;
  height: 32px;
  font-size: 14px;
  border-radius: 4px;
}
.mfp-bottom-bar .mfp-title {
  font-size: 26px;
  margin-top: 20px;
}
div.mfp-close {
  top: -50px !important;
}
div.mfp-close:after {
  content: '';
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAAqCAQAAABvygHQAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAACxIAAAsSAdLdfvwAAAAHdElNRQflAxcQAzb4glBNAAADNklEQVRIx72XP2wTSRSHv90ETETSJFWQneYcWwiBASGFVBCqO+lACCQ6kEAgIhwqmigS2AFBGTogpwunK8+n03URVxxJgQQFwYBAShz+FE6wA+iKCzQg8qPwbuxde3YdQPymGc2b+fzmvbeeGUsEaD39DLCNBN20A+8oUeAxU9zlg3mZZYTuYJBDvOA2MxRY4D2wkSi97GKAOH9xg7xhrRq1lCZV1Ih6hKH1aERFTSrVyFo/tEFjKmlIESPQbRENqaSr2hAGjSuvnLpCgW7r0p/KKx4E7VNZ6aaBbhtSWX0maJ/eaP+akQj9rKVabO3GSzrwRUiEDqhUDUI1PXmd/WIkQmeVd1Pm1ukYUY5Uus9Yi+LVbo4i56rFn+IftvLGA7UI/NiwWfFBO3nCjzwGG4ArXHKRjlr4nUwg8lcu+8b+4yJXAFqBnWzhoG/JbxwFYNSIPA6scN4zPsEwO8jbwBnG+egxrvASgGxDb6vIlz7LR8ZJg6UIZbZRrFqcmI5ywQGPGpCnuOmJKUCMR3Tb7GauFrmqDBcdaNaMrFORefpasico8q833o6msdkD7MVi2oTs9GN/oMcmxX1Mcr3NkG3Cy4pmSLXSyxwEYOECkMEi1gQSZklYekuiuuOaRFXlpoxGyLh/dhdzNh0sE6wMl5yeQrwEWKbDxiJMNlGnZ9ETOhuw+Z+OkBmV9MjxOhtC7GDZZolNTSBXOOmpBLO6WWplniRPQpFuLCuVQAA4SaElu5moofjrkfWfQ33xH2PR5jb7mvSysvmwIAwwZXOP5Gp2w5Dh2BhJ7rVkP5Egxh3P9i0mnIw3qktPEHzbT1Pmbxu4xiDrPCZRBsRJQ6lXvBWvfOPrGOR65Z9/hllOcc1jHkE8D/h6MohX/OIbPcEsM+7Bt51bdQdfk/IdfD/xiNVzf0y5rzr1EfpDY97LRNs3uEw8VNt3uPZ87QXtdeMLWgVb1pk1IwOvkgj1Kq+cOpsGdiqnvBLeUdtXIfP0s8hT0kRCqylCmqcs0k/BZ2n4+ylNakHDihk9jGlYC6aHhPnJs5PTHOYZU9ynwALvgHaiJFafPOM8aLzUaupxlqx5nM2FP84+A2X4R/beYIClAAAAAElFTkSuQmCC) no-repeat center;
  width: 42px;
  height: 42px;
  background-origin: content-box;
  background-size: contain;
  display: inline-block;
}
button.mfp-close {
  display: none !important;
}
.pagination-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 75px;
}
.pagination-wrap .page-nav {
  width: 30px;
  height: 30px;
  border-radius: 4px;
  border: 1px solid #ededed;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pagination-wrap .page-nav.disabled {
  opacity: 0.6;
  cursor: default;
}
.pagination-wrap .page-prev {
  margin-right: 15px;
}
.pagination-wrap .page-next {
  margin-left: 15px;
}
.pagination-wrap .number {
  padding: 0 15px;
  color: #666;
}
.pagination-wrap .number:hover {
  color: #333;
}
.pagination-wrap .number.on {
  color: #a50082;
}
.pagination-wrap .number + .number {
  margin-left: 15px;
}
.pagination-wrap .total-pages {
  font-size: 14px;
  color: #999;
  margin-left: 25px;
}
.mb40 {
  margin-bottom: 40px !important;
}
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex {
  display: flex;
}
.basis-xs {
  flex-basis: 20%;
}
.basis-sm {
  flex-basis: 40%;
}
.basis-df {
  flex-basis: 50%;
}
.basis-lg {
  flex-basis: 60%;
}
.basis-xl {
  flex-basis: 80%;
}
.flex-sub {
  flex: 1;
}
.flex-twice {
  flex: 2;
}
.flex-treble {
  flex: 3;
}
.flex-direction {
  flex-direction: column;
}
.flex-wrap {
  flex-wrap: wrap;
}
.align-start {
  align-items: flex-start;
}
.align-end {
  align-items: flex-end;
}
.align-center {
  align-items: center;
}
.align-stretch {
  align-items: stretch;
}
.self-start {
  align-self: flex-start;
}
.self-center {
  align-self: center;
}
.self-end {
  align-self: flex-end;
}
.self-stretch {
  align-self: stretch;
}
.align-stretch {
  align-items: stretch;
}
.justify-start {
  justify-content: flex-start;
}
.justify-end {
  justify-content: flex-end;
}
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.justify-around {
  justify-content: space-around;
}
.title-desc-item {
  font-size: 14px;
}
.not-margin {
  margin: 0;
}
.fz14 {
  font-size: 14px !important;
}
.mt10 {
  margin-top: 10px !important;
}
.mb10 {
  margin-bottom: 10px !important;
}
.mb50 {
  margin-bottom: 50px !important;
}
.mb30 {
  margin-bottom: 30px !important;
}
.red-point-mark {
  position: relative;
}
.red-point-mark:after {
  content: '';
  position: absolute;
  right: -7px;
  top: 2px;
  background-color: #f20013;
  width: 5px;
  height: 5px;
  border-radius: 50%;
}
.title-under-line-box {
  border-bottom: 1px solid #e5e5e5;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.title-under-line-box .left {
  display: inline-flex;
}
.title-under-line-box .title {
  display: inline-block;
  font-size: 18px;
  color: #333;
  padding: 7px 0;
  box-sizing: border-box;
  border-bottom: 3px solid #ebb532;
}
.title-under-line-box .title.off {
  border-bottom: 0;
  color: #333;
}
.title-under-line-box .title + .title {
  margin-left: 70px;
}
.title-under-line-box .right {
  display: flex;
}
.title-under-line-box .right .btn {
  padding-left: 18px;
  padding-right: 18px;
  height: 28px;
  line-height: 28px;
  font-size: 14px;
}
.title-under-line-box .right .btn + .btn {
  margin-left: 18px;
}
.title-under-line-box .more {
  float: right;
  font-size: 14px;
  color: #ebb532;
  line-height: 50px;
  height: 50px;
}
.header {
  padding: 10px 0;
  transition: all 0.3s;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  background-color: #fff;
  height: 80px;
  z-index: 3;
  box-shadow: 0 2px 8px 0 rgba(2, 30, 7, 0.1);
}
.header__container {
  min-width: 1080px;
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header__container .logo {
  height: 60px;
  width: auto;
}
.header__container .header-right {
  position: relative;
  display: flex;
  align-items: center;
}
.header__container .header-right .header-right__left {
  display: flex;
  align-items: center;
}
.header__container .header-right .header-right__left .nav-list {
  display: flex;
  align-items: center;
  font-size: 16px;
}
.header__container .header-right .header-right__left .nav-list > li {
  position: relative;
}
.header__container .header-right .header-right__left .nav-list > li.donate-item a {
  color: #fff;
}
.header__container .header-right .header-right__left .nav-list > li.donate-item a:hover {
  color: #fff;
}
.header__container .header-right .header-right__left .nav-list > li.donate-item:after {
  display: none;
}
.header__container .header-right .header-right__left .nav-list > li .btn {
  padding: 0 14px;
  height: 32px;
  font-size: 14px;
}
.header__container .header-right .header-right__left .nav-list > li:after {
  content: '';
  height: 40px;
  position: absolute;
  left: 0;
  top: 17px;
  width: 100%;
  background-color: transparent;
}
.header__container .header-right .header-right__left .nav-list > li + li {
  margin-left: 35px;
}
.header__container .header-right .header-right__left .nav-list > li > a {
  color: #333;
  padding-left: 5px;
}
.header__container .header-right .header-right__left .nav-list > li.active > a,
.header__container .header-right .header-right__left .nav-list > li.now > a {
  color: #ebb532;
}
.header__container .header-right .header-right__left .nav-list > li .submenu {
  font-size: 14px;
  display: none;
  position: absolute;
  left: 0;
  top: 50px;
  width: 180px;
  background-color: #fff;
  box-shadow: 0 2px 8px 0 rgba(2, 30, 7, 0.1);
  border-bottom: 4px solid #ebb532;
}
.header__container .header-right .header-right__left .nav-list > li .submenu > li a {
  height: 45px;
  line-height: 45px;
  padding-left: 25px;
  display: block;
  color: #666;
}
.header__container .header-right .header-right__left .nav-list > li .submenu > li:hover a,
.header__container .header-right .header-right__left .nav-list > li .submenu > li.active a {
  color: #ebb532;
}
.header__container .header-right .header-right__left .search-wrapper {
  margin-left: 50px;
  display: inline-flex;
}
.header__container .header-right .header-right__right {
  position: absolute;
  right: -120px;
  top: 50%;
  margin-top: -11px;
}
.header__container .header-right .header-right__right .language-box {
  display: flex;
  align-items: center;
  margin-left: 50px;
  flex-shrink: 0;
  white-space: nowrap;
}
.header__container .header-right .header-right__right .language-box .lang-item {
  color: #666;
  position: relative;
  cursor: pointer;
}
.header__container .header-right .header-right__right .language-box .lang-item + .lang-item {
  margin-left: 8px;
  padding-left: 8px;
}
.header__container .header-right .header-right__right .language-box .lang-item + .lang-item:before {
  content: '';
  position: absolute;
  left: 0;
  top: 4px;
  height: 12px;
  border-left: 1px solid #666;
}
.header__container .header-right .header-right__right .language-box .lang-item.active {
  cursor: default;
  color: #a50082;
}
.footer .footer-top {
  background: url('../../../../img/pc/bg-index-footer.jpg') no-repeat center;
  background-size: cover;
  height: 380px;
  display: flex;
  align-items: center;
}
.footer .footer-top .big-container {
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 1180px;
}
.footer .footer-top .big-container .footer-top__item {
  flex-grow: 1;
  flex-shrink: 0;
  border-radius: 14px;
  height: 240px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 640px;
  color: #fff;
}
.footer .footer-top .big-container .footer-top__item + .footer-top__item {
  margin-left: 30px;
}
.footer .footer-top .big-container .footer-top__item .title {
  word-break: break-word;
  margin-bottom: 18px;
  font-size: 26px;
}
.footer .footer-top .big-container .footer-top__item .desc {
  word-break: break-word;
  margin-bottom: 23px;
  font-size: 16px;
  max-width: 420px;
  text-align: center;
}
.footer .footer-top .big-container .footer-top__item .btn-area {
  display: flex;
  align-items: center;
}
.footer .footer-top .big-container .footer-top__item .btn-area .btn {
  font-size: 14px;
  height: 38px;
  padding: 0 25px;
  background-color: transparent;
  color: #fff;
}
.footer .footer-top .big-container .footer-top__item .btn-area .btn + .btn {
  margin-left: 20px;
}
.footer .footer-top .big-container .footer-top__item.month-pay {
  background: url('../../../../img/pc/bg-footer-month-pay.png') no-repeat center;
  background-size: cover;
}
.footer .footer-top .big-container .footer-top__item.month-pay .btn:hover {
  background-color: #fff;
  color: #ebb532;
}
.footer .footer-top .big-container .footer-top__item.join-us {
  background: url('../../../../img/pc/bg-footer-join-us.png') no-repeat center;
  background-size: cover;
}
.footer .footer-top .big-container .footer-top__item.join-us .btn:hover {
  background-color: #fff;
  color: #71ba2b;
}
.footer .footer-content {
  background-color: #cea950;
  font-size: 14px;
}
.footer .footer-content .container {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
}
.footer .footer-content .container .keep-record {
  margin-left: 50px;
  display: inline-block;
}
.footer .footer-content .container a {
  color: #fff;
}
.footer.footer-en .big-container .footer-top__item .btn-area {
  min-width: 170px;
}
@media screen and (max-width: 1670px) {
  .header__container .header-right .header-right__left .search-wrapper {
    margin-left: 30px;
  }
  .header__container .header-right .header-right__right {
    position: static;
    right: unset;
    margin-top: 0;
  }
  .header__container .header-right .header-right__right .language-box {
    margin-left: 30px;
  }
}
@media screen and (max-width: 1450px) {
  .header__container {
    padding-left: 15px;
    padding-right: 15px;
  }
  .footer .footer-top .big-container {
    padding-left: 15px;
    padding-right: 15px;
  }
  .footer .footer-top .big-container .footer-top__item {
    width: 580px;
  }
}
@media screen and (max-width: 1180px) {
  .header__container {
    padding-left: 15px;
    padding-right: 15px;
  }
  .header__container .header-right .header-right__left .nav-list > li + li {
    margin-left: 25px;
  }
  .header__container .header-right .header-right__left .search-wrapper {
    margin-left: 20px;
  }
  .header__container .header-right .header-right__right .language-box {
    margin-left: 20px;
  }
  .footer .footer-top .big-container .footer-top__item {
    width: 520px;
  }
}
.banner {
  height: 300px;
  color: #fff;
  background: url('../../../../img/pc/about-banner.jpg') no-repeat center;
  background-size: cover;
}
.banner .container {
  height: 100%;
  position: relative;
}
.banner .container .title-wrapper {
  position: absolute;
  top: 50%;
  right: 0;
  text-shadow: 0 2px 3px rgba(35, 34, 3, 0.26);
  text-align: right;
  height: 86px;
  margin-top: -43px;
}
.banner .container .title-wrapper .title-ch {
  font-size: 42px;
}
.banner .container .title-wrapper .title-en {
  font-size: 18px;
}
.content-tab {
  padding-left: 40px;
  padding-right: 40px;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 100px;
  box-shadow: 0 2px 8px 0 rgba(2, 30, 7, 0.1);
  border-radius: 4px;
  background-color: #fff;
}
.content-tab > li {
  position: relative;
}
.content-tab > li a {
  color: #333;
}
.content-tab > li a:hover {
  color: #a50082;
}
.content-tab > li.active {
  position: relative;
}
.content-tab > li.active:after {
  content: '';
  height: 2px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -15px;
  background: url(data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEASABIAAD/2wBDAAMCAgMCAgMDAwMEAwMEBQgFBQQEBQoHBwYIDAoMDAsKCwsNDhIQDQ4RDgsLEBYQERMUFRUVDA8XGBYUGBIUFRT/2wBDAQMEBAUEBQkFBQkUDQsNFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBT/wAARCAACAFADAREAAhEBAxEB/8QAGgAAAQUBAAAAAAAAAAAAAAAABgACAwQHBf/EAB4QAAEEAQUAAAAAAAAAAAAAAAABAwUxAgQyM0FC/8QAGwEAAgIDAQAAAAAAAAAAAAAABQYDBwACBAn/xAAcEQEAAgIDAQAAAAAAAAAAAAAAAzEBBAIFQTL/2gAMAwEAAhEDEQA/AMEY6LGkrL0NioVwdoVz3FZQzU0aA8lCd16VNv0fRnHiVDtfeSjsiDSUgH5lbZ9dRqjk5FTYtZxsiyBypDUIlIwJlMd2nTHYRPWX/9k=) no-repeat center;
  background-size: cover;
}
.content-tab.banner-content-tab {
  margin-top: -50px;
  margin-bottom: 80px;
}
.content-tab.banner-content-tab.banner-content-tab-2 {
  padding-left: 260px;
  padding-right: 260px;
}
.content-tab.banner-content-tab.banner-content-tab-3 {
  padding-left: 160px;
  padding-right: 160px;
}
.content-tab.banner-content-tab.banner-content-tab-4 {
  padding-left: 110px;
  padding-right: 110px;
}
.page-index .index-swiper {
  width: 100%;
  min-width: 1180px;
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}
.page-index .index-swiper .swiper-pagination {
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
}
.page-index .index-swiper .swiper-pagination .swiper-pagination-bullet {
  width: 100px;
  height: 3px;
  border-radius: 0;
  background-color: #fff;
  opacity: 0.5;
  position: relative;
}
.page-index .index-swiper .swiper-pagination .swiper-pagination-bullet + .swiper-pagination-bullet {
  margin-left: 25px;
}
.page-index .index-swiper .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  opacity: 1;
}
.page-index .index-swiper .swiper-prev,
.page-index .index-swiper .swiper-next {
  font-size: 0;
  position: absolute;
  top: 50%;
  z-index: 2;
  cursor: pointer;
  margin-top: -20px;
}
.page-index .index-swiper .swiper-prev {
  left: 50px;
}
.page-index .index-swiper .swiper-next {
  right: 50px;
}
.page-index .news-project-box .content-block__content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.page-index .news-project-box .content-block__content .news-project-box-col {
  height: 444px;
  position: relative;
}
.page-index .news-project-box .content-block__content .news-project-box__left {
  width: 770px;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
}
.page-index .news-project-box .content-block__content .news-project-box__left .news-project-box__left-item {
  width: 366px;
  display: flex;
  flex-direction: column;
  transition: all 0.3s;
  box-shadow: 0 2px 8px 0 rgba(2, 30, 7, 0.1);
  overflow: hidden;
}
.page-index .news-project-box .content-block__content .news-project-box__left .news-project-box__left-item .img img {
  transition: all 0.3s;
}
.page-index .news-project-box .content-block__content .news-project-box__left .news-project-box__left-item:hover .img img {
  transform: scale(1.05);
}
.page-index .news-project-box .content-block__content .news-project-box__left .news-project-box__left-item:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 10px;
  transform: translateY(5px);
  transition: all 0.3s;
}
.page-index .news-project-box .content-block__content .news-project-box__left .news-project-box__left-item:first-child:hover .content .title {
  color: #ebb532;
}
.page-index .news-project-box .content-block__content .news-project-box__left .news-project-box__left-item:first-child:after {
  background-color: #dab866;
}
.page-index .news-project-box .content-block__content .news-project-box__left .news-project-box__left-item:nth-child(2):hover .content .title {
  color: #71ba2b;
}
.page-index .news-project-box .content-block__content .news-project-box__left .news-project-box__left-item:nth-child(2):after {
  background-color: #71ba2b;
}
.page-index .news-project-box .content-block__content .news-project-box__left .news-project-box__left-item:hover .content .desc {
  color: #333;
}
.page-index .news-project-box .content-block__content .news-project-box__left .news-project-box__left-item:hover:after {
  transform: translateY(0);
}
.page-index .news-project-box .content-block__content .news-project-box__left .news-project-box__left-item .img {
  width: 100%;
  height: 258px;
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 0;
  flex-shrink: 0;
}
.page-index .news-project-box .content-block__content .news-project-box__left .news-project-box__left-item .content {
  flex-grow: 1;
  padding: 20px;
}
.page-index .news-project-box .content-block__content .news-project-box__left .news-project-box__left-item .content .time {
  font-size: 30px;
  color: #999;
  margin-bottom: 4px;
}
.page-index .news-project-box .content-block__content .news-project-box__left .news-project-box__left-item .content .title {
  color: #333;
  font-size: 20px;
  margin-bottom: 10px;
  overflow: hidden !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 1.5;
}
.page-index .news-project-box .content-block__content .news-project-box__left .news-project-box__left-item .content .desc {
  font-size: 14px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  word-break: break-all;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  color: #666;
  line-height: 1.8;
}
.page-index .news-project-box .content-block__content .news-project-box__right .news-project-box__right-item {
  position: relative;
  display: flex;
  width: 363px;
}
.page-index .news-project-box .content-block__content .news-project-box__right .news-project-box__right-item .img img {
  transition: all 0.3s;
}
.page-index .news-project-box .content-block__content .news-project-box__right .news-project-box__right-item:hover .img img {
  transform: scale(1.05);
}
.page-index .news-project-box .content-block__content .news-project-box__right .news-project-box__right-item + .news-project-box__right-item {
  margin-top: 21px;
}
.page-index .news-project-box .content-block__content .news-project-box__right .news-project-box__right-item .img {
  width: 100%;
  height: 211px;
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 0;
  flex-shrink: 0;
}
.page-index .news-project-box .content-block__content .news-project-box__right .news-project-box__right-item .desc {
  position: absolute;
  left: 20px;
  right: 20px;
  bottom: 10px;
  color: #fff;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  word-break: break-all;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}
.page-index .data-panel {
  background: url('../../../../img/pc/bg-index-data.jpg') no-repeat center;
  background-size: cover;
  height: 605px;
}
.page-index .data-panel .data-panel__top {
  padding-top: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px;
}
.page-index .data-panel .data-panel__top .data-panel__top-left {
  display: flex;
  align-items: center;
}
.page-index .data-panel .data-panel__top .data-panel__top-left .data-panel-list .total-num {
  width: 490px;
}
.page-index .data-panel .data-panel__top .data-panel__top-right {
  display: flex;
  align-items: center;
}
.page-index .data-panel .data-panel__top .data-panel__top-right .btn + .btn {
  margin-left: 20px;
}
.page-index .data-panel .data-panel__bottom {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.page-index .data-panel .data-panel__bottom .main-wrapper {
  overflow: hidden;
  height: 248px;
  position: relative;
  margin-top: -13px;
}
.page-index .data-panel .data-panel__bottom .main-wrapper:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1;
  background-color: #fff;
  height: 26px;
}
.page-index .data-panel .data-panel__bottom .data-panel__bottom-item {
  background-color: #fff;
  width: 565px;
  max-height: 342px;
  padding: 35px 20px 20px;
}
.page-index .data-panel .data-panel__bottom .data-panel__bottom-item .tab-list {
  position: relative;
  z-index: 2;
  font-size: 20px;
}
.page-index .data-panel .data-panel__bottom .data-panel__bottom-item .tab-list li {
  cursor: pointer;
  display: inline-block;
}
.page-index .data-panel .data-panel__bottom .data-panel__bottom-item .tab-list li + li {
  margin-left: 50px;
}
.page-index .data-panel .data-panel__bottom .data-panel__bottom-item .tab-list li a {
  color: #666;
}
.page-index .data-panel .data-panel__bottom .data-panel__bottom-item .tab-list li.active {
  color: #a50082;
  padding-bottom: 5px;
  border-bottom: 1px solid #a50082;
}
.page-index .data-panel .data-panel__bottom .data-panel__bottom-item .tab-list li.active a {
  color: #a50082;
}
.page-index .data-panel .data-panel__bottom .data-panel__bottom-item .donate-slide-wrapper {
  overflow: hidden;
  height: 235px;
  padding-top: 15px;
}
.page-index .data-panel .data-panel__bottom .data-panel__bottom-item .donate-slide-wrapper .donate-slide {
  overflow: hidden;
  font-size: 14px;
  color: #333;
}
.page-index .data-panel .data-panel__bottom .data-panel__bottom-item .donate-slide-wrapper .donate-slide .swiper-slide {
  display: flex;
  align-items: center;
  height: 35px;
}
.page-index .data-panel .data-panel__bottom .data-panel__bottom-item .donate-slide-wrapper .donate-slide .swiper-slide .user-name {
  width: 115px;
  padding-right: 15px;
  overflow: hidden !important;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.page-index .data-panel .data-panel__bottom .data-panel__bottom-item .donate-slide-wrapper .donate-slide .swiper-slide .project-name {
  width: 230px;
  padding-right: 55px;
  overflow: hidden !important;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.page-index .data-panel .data-panel__bottom .data-panel__bottom-item .donate-slide-wrapper .donate-slide .swiper-slide .donate-count {
  overflow: hidden !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 103px;
  padding-right: 15px;
}
.page-index .data-panel .data-panel__bottom .data-panel__bottom-item .donate-slide-wrapper .donate-slide .swiper-slide .donate-time {
  overflow: hidden !important;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.page-index .data-panel .data-panel__bottom .data-panel__bottom-item .data-panel-data-list {
  font-size: 14px;
  padding-top: 15px;
}
.page-index .data-panel .data-panel__bottom .data-panel__bottom-item .data-panel-data-list > li {
  height: 44px;
  display: flex;
  align-items: center;
}
.page-index .data-panel .data-panel__bottom .data-panel__bottom-item .data-panel-data-list > li:hover a .title {
  color: #ebb532;
}
.page-index .data-panel .data-panel__bottom .data-panel__bottom-item .data-panel-data-list > li a {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #333;
}
.page-index .welfare-project-box .welfare-project-list {
  display: flex;
  align-items: center;
}
.page-index .welfare-project-box .welfare-project-list > li .img img {
  transition: all 0.3s;
}
.page-index .welfare-project-box .welfare-project-list > li:hover .img img {
  transform: scale(1.05);
}
.page-index .welfare-project-box .welfare-project-list > li + li {
  margin-left: 1px;
}
.page-index .welfare-project-box .welfare-project-list > li a {
  width: 393px;
  height: 277px;
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 0;
  flex-shrink: 0;
  color: #fff;
  display: flex;
  position: relative;
}
.page-index .welfare-project-box .welfare-project-list > li a .title {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 20px;
  text-align: center;
  font-size: 20px;
  padding-left: 10%;
  padding-right: 10%;
  overflow: hidden !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: bold;
}
.page-index .welfare-project-box .welfare-project-list > li a:hover .content-box {
  opacity: 1;
}
.page-index .welfare-project-box .welfare-project-list > li a .content-box {
  transition: all 0.3s;
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  padding-top: 40px;
  background-color: #ecb530;
}
.page-index .welfare-project-box .welfare-project-list > li a .content-box .content-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 27px;
  text-align: center;
}
.page-index .welfare-project-box .welfare-project-list > li a .content-box .content-title:before,
.page-index .welfare-project-box .welfare-project-list > li a .content-box .content-title:after {
  content: '';
  background-color: #fff;
  width: 16px;
  height: 2px;
  display: inline-block;
  vertical-align: middle;
}
.page-index .welfare-project-box .welfare-project-list > li a .content-box .desc {
  font-size: 16px;
  line-height: 1.8;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  word-break: break-all;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  padding-left: 25px;
  padding-right: 25px;
  margin-bottom: 20px;
}
.page-index .welfare-project-box .welfare-project-list > li a .content-box .to-box {
  position: absolute;
  left: 50%;
  bottom: 25px;
  margin-left: -19px;
  border-radius: 50%;
  border: 1px solid #fff;
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.page-index .welfare-project-box .welfare-project-list > li a .content-box .to-box i {
  transition: all 0.3s;
  transition-delay: 0.1s;
}
.page-index .welfare-project-box .welfare-project-list > li a .content-box .to-box:hover i {
  transform: translateX(3px);
}
.page-index .map-panel {
  background-color: #e3c06e;
  height: 190px;
  overflow: hidden;
  padding-top: 50px;
  position: relative;
  z-index: 1;
}
.page-index .map-panel .data-panel-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
}
.page-index .map-panel .data-panel-list .item .data-count {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 42px;
}
.page-index .map-panel .data-panel-list .item .data-count .number {
  font-size: 42px;
}
.page-index .map-panel .data-panel-list .item .data-count .num-overflow {
  margin-left: 10px;
  font-weight: bold;
}
.page-index .map-panel .data-panel-list .item .desc {
  font-size: 16px;
  text-align: center;
}
.page-index .map-panel .echart-wrapper {
  margin-top: 45px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}
.page-index .map-panel .echart-wrapper .province-data-panel {
  display: none;
  background-color: #fff;
  padding: 25px 30px;
  border-radius: 4px;
  box-shadow: 0 2px 8px 0 rgba(2, 30, 7, 0.1);
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  transition: top 0.3s, left 0.3s;
}
.page-index .map-panel .echart-wrapper .province-data-panel .province-name {
  font-size: 20px;
  color: #333;
  white-space: nowrap;
}
.page-index .map-panel .echart-wrapper .province-data-panel .province-data-item {
  font-size: 16px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  margin-top: 5px;
}
.page-index .map-panel .echart-wrapper .province-data-panel .province-data-item .province-data-title {
  color: #666;
}
.page-index .map-panel .echart-wrapper .province-data-panel .province-data-item .province-data {
  color: blue;
}
.page-index .map-panel .echart-wrapper #echart {
  width: 740px;
  height: 540px;
  margin-left: auto;
  margin-right: auto;
}
.page-index .map-panel .echart-wrapper #echart > div {
  pointer-events: unset !important;
}
.page-index .map-panel .echart-wrapper #echart > div .echart-data-box {
  width: 380px;
  border-radius: 4px;
  background-color: #fff;
  border-top: 5px solid #ef8007;
  padding: 30px;
}
.page-index .map-panel .echart-wrapper #echart > div .echart-data-box .province-name {
  font-size: 24px;
  color: #ef8007;
}
.page-index .map-panel .echart-wrapper #echart > div .echart-data-box .item {
  padding-top: 25px;
  padding-bottom: 25px;
  border-bottom: 1px solid #ededed;
}
.page-index .map-panel .echart-wrapper #echart > div .echart-data-box .item .title {
  color: #333;
  font-size: 18px;
  margin-bottom: 10px;
  line-height: 1.5;
}
.page-index .map-panel .echart-wrapper #echart > div .echart-data-box .item .echart-activity-list {
  font-size: 16px;
}
.page-index .map-panel .echart-wrapper #echart > div .echart-data-box .item .echart-activity-list > li {
  padding-left: 20px;
  position: relative;
}
.page-index .map-panel .echart-wrapper #echart > div .echart-data-box .item .echart-activity-list > li:before {
  content: '';
  position: absolute;
  left: 0;
  top: 10px;
  width: 4px;
  height: 4px;
  background-color: #666;
  border-radius: 50%;
}
.page-index .map-panel .echart-wrapper #echart > div .echart-data-box .item .echart-activity-list > li + li {
  margin-top: 5px;
}
.page-index .map-panel .echart-wrapper #echart > div .echart-data-box .item .echart-activity-list > li a {
  display: block;
  line-height: 1.5;
  color: #666;
}
.page-index .home-partner-swiper {
  margin: 0 -5px;
  width: 100%;
}
.page-index .home-partner-swiper .swiper-slide {
  font-size: 0;
  height: 120px;
  padding: 20px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.page-index .home-partner-swiper .swiper-slide a,
.page-index .home-partner-swiper .swiper-slide span {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.page-index .home-partner-swiper .swiper-slide a img,
.page-index .home-partner-swiper .swiper-slide span img {
  max-width: 100%;
  max-height: 100%;
}
.floating-wrapper {
  position: absolute;
  right: 0;
  top: 55%;
  z-index: 3;
}
.floating-wrapper .float-list > li {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #e6e6e6;
  width: 52px;
  height: 52px;
  transition: all 0.3s;
  font-size: 14px;
  position: relative;
}
.floating-wrapper .float-list > li a {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.floating-wrapper .float-list > li .icon-wrapper {
  font-size: 0;
  margin-bottom: 0.05rem;
}
.floating-wrapper .float-list > li .icon-wrapper .icon:first-child {
  display: none;
}
.floating-wrapper .float-list > li .icon-wrapper .icon:last-child {
  display: inline-block;
}
.floating-wrapper .float-list > li .text {
  color: #333;
}
.floating-wrapper .float-list > li:hover,
.floating-wrapper .float-list > li.active {
  background-color: #ebb532;
}
.floating-wrapper .float-list > li:hover .icon:first-child,
.floating-wrapper .float-list > li.active .icon:first-child {
  display: inline-block;
}
.floating-wrapper .float-list > li:hover .icon:last-child,
.floating-wrapper .float-list > li.active .icon:last-child {
  display: none;
}
.floating-wrapper .float-list > li:hover .text,
.floating-wrapper .float-list > li.active .text {
  color: #fff;
}
.floating-wrapper .float-list > li + li {
  margin-top: 1px;
}
.floating-wrapper .float-list > li.qr-code-box:hover .qr-code {
  display: block;
}
.floating-wrapper .float-list > li.qr-code-box .qr-code {
  box-shadow: 0 2px 8px 0 rgba(2, 30, 7, 0.1);
  position: absolute;
  right: 100%;
  top: 0;
  padding: 20px;
  display: none;
  background-color: #fff;
  width: 130px;
  height: 157px;
}
.floating-wrapper .float-list > li.qr-code-box .qr-code .img {
  width: 90px;
  height: 90px;
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 0;
  flex-shrink: 0;
}
.floating-wrapper .float-list > li.qr-code-box .qr-code .desc {
  margin-top: 20px;
  color: #a50082;
  font-size: 12px;
  text-align: center;
}
.page-service-index .column-panel .column-left .service-content {
  display: flex;
  padding-top: 30px;
  padding-bottom: 30px;
}
.page-service-index .column-panel .column-left .service-content .img img {
  transition: all 0.3s;
}
.page-service-index .column-panel .column-left .service-content:hover .img img {
  transform: scale(1.05);
}
.page-service-index .column-panel .column-left .service-content .img {
  width: 300px;
  height: 212px;
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 0;
  flex-shrink: 0;
}
.page-service-index .column-panel .column-left .service-content .content {
  max-width: 460px;
  padding-top: 13px;
  margin-left: 35px;
  flex-shrink: 0;
}
.page-service-index .column-panel .column-left .service-content .content .title {
  margin-bottom: 25px;
  font-size: 24px;
  color: #333;
}
.page-service-index .column-panel .column-left .service-content .content .icon-desc-wrapper {
  margin-bottom: 20px;
}
.page-service-index .column-panel .column-left .service-content .content .icon-desc-wrapper .desc {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  word-break: break-all;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}
.page-service-index .column-panel .column-left .article-content {
  padding-bottom: 20px;
}
.page-service-index .column-panel .column-left .activity-list-wrapper {
  padding-bottom: 60px;
}
.page-service-index .column-panel .column-left .activity-list-wrapper .load-more {
  margin-top: 50px;
}
.page-search-index .meta {
  background-color: #dab866;
  height: 200px;
  padding-top: 50px;
  margin-bottom: 40px;
}
.page-search-index .meta .title {
  text-align: center;
  font-size: 24px;
  color: #fff;
  margin-bottom: 15px;
}
.page-search-index .no-search-data {
  color: #666;
  font-size: 14px;
  margin-bottom: 60px;
}
.page-search-index .search-list li a {
  border-bottom: 1px solid #ededed;
  padding: 25px 30px;
  display: block;
  transition: all 0.3s;
  border-radius: 4px;
}
.page-search-index .search-list li a:hover {
  box-shadow: 0 2px 8px 0 rgba(2, 30, 7, 0.1);
  border-bottom: 1px solid transparent;
}
.page-search-index .search-list li a:hover .title {
  color: #a50082;
}
.page-search-index .search-list li a .title {
  color: #333;
  font-size: 18px;
  margin-bottom: 15px;
  overflow: hidden !important;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.page-search-index .search-list li a .time {
  color: #999;
  font-size: 14px;
  margin-bottom: 15px;
}
.page-search-index .search-list li a .desc {
  color: #666;
  font-size: 14px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  word-break: break-all;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}
.page-about-org .button-tab {
  margin-top: 70px;
}
.page-about-org .team-list {
  display: flex;
  flex-wrap: wrap;
  margin-left: -50px;
  margin-bottom: -50px;
}
.page-about-org .team-list > li {
  margin-left: 50px;
  margin-bottom: 50px;
  width: 195px;
  box-shadow: 0 2px 8px 0 rgba(2, 30, 7, 0.1);
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}
.page-about-org .team-list > li:after {
  content: '';
  transition: all 0.3s;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  border-bottom: 2px solid #dab866;
}
.page-about-org .team-list > li:hover:after {
  width: 100%;
}
.page-about-org .team-list > li .img {
  width: 100%;
  height: 145px;
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 0;
  flex-shrink: 0;
}
.page-about-org .team-list > li .content {
  padding: 18px 15px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.page-about-org .team-list > li .content .top {
  display: flex;
  align-items: baseline;
  margin-bottom: 6px;
}
.page-about-org .team-list > li .content .top .name {
  color: #333;
  font-size: 16px;
  flex-shrink: 0;
}
.page-about-org .team-list > li .content .top .position {
  margin-left: 15px;
  color: #666;
  font-size: 14px;
  overflow: hidden !important;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.page-about-org .team-list > li .content .bottom {
  color: #666;
  font-size: 14px;
}
.page-about-honor .honor-list {
  display: flex;
  flex-wrap: wrap;
  margin-left: -50px;
  margin-bottom: -50px;
}
.page-about-honor .honor-list > li {
  margin-left: 50px;
  margin-bottom: 50px;
  box-shadow: 0 2px 8px 0 rgba(2, 30, 7, 0.1);
  font-size: 16px;
  width: 257px;
  color: #333;
  cursor: pointer;
  display: flex;
  flex-direction: column;
}
.page-about-honor .honor-list > li .img img {
  transition: all 0.3s;
}
.page-about-honor .honor-list > li:hover .img img {
  transform: scale(1.05);
}
.page-about-honor .honor-list > li .img {
  width: 100%;
  height: 181px;
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 0;
  flex-shrink: 0;
}
.page-about-honor .honor-list > li .content {
  font-size: 16px;
  padding: 20px 15px;
  line-height: 1.6;
  display: flex;
  align-items: center;
  justify-content: center;
}
.page-about-honor .honor-list > li:hover {
  color: #a50082;
}
@keyframes location_jump {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0);
  }
}
.page-about-contact .contact-box {
  display: flex;
  align-items: center;
  height: 520px;
}
.page-about-contact .contact-box .map {
  width: 694px;
  flex-shrink: 0;
  position: relative;
}
.page-about-contact .contact-box .map #mapBox {
  height: 520px;
}
.page-about-contact .contact-box .map #mapBox .BMap_Marker {
  animation: location_jump 1s infinite;
  animation-direction: alternate;
}
.page-about-contact .contact-box .map #mapBox img {
  max-width: unset !important;
  max-height: unset !important;
}
.page-about-contact .contact-box .content {
  color: #fff;
  flex-grow: 1;
  padding-top: 55px;
  padding-left: 30px;
  padding-right: 30px;
  height: 100%;
  font-size: 16px;
  background: url('../../../../img/pc/bg-contact.jpg') no-repeat center;
  background-size: cover;
}
.page-about-contact .contact-box .content .content-list > li {
  margin-bottom: 20px;
}
.page-about-contact .contact-box .content .qr-code {
  width: 160px;
  height: 160px;
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 0;
  flex-shrink: 0;
  padding: 10px;
  background-color: #fff;
}
.page-project-index .project-index-header {
  color: #fff;
  padding-top: 90px;
  text-align: center;
  background-color: #dab866;
  padding-bottom: 110px;
  margin-bottom: 100px;
  padding-left: 30px;
  padding-right: 30px;
}
.page-project-index .project-index-header .title {
  font-size: 32px;
  margin-bottom: 35px;
}
.page-project-index .project-index-header .desc {
  font-size: 18px;
  line-height: 1.8;
}
.page-project-index .points-connection-box {
  padding-top: 130px;
  margin-top: 30px;
  position: relative;
  transform: translateX(-64px);
}
.page-project-index .points-connection-box .bg-points-connection {
  width: 750px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.page-project-index .points-connection-box .points-connection-item {
  position: absolute;
}
.page-project-index .points-connection-box .points-connection-item.points-connection-item-hover:hover .points-connection-item-img {
  transform: translateY(-10px);
}
.page-project-index .points-connection-box .points-connection-item-img {
  transition: all 1s;
  position: relative;
  z-index: 2;
  width: 100%;
}
.page-project-index .points-connection-box .bg-points-connection-item {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.page-project-index .points-connection-box .site-project {
  width: 280px;
  top: -18px;
  left: 505px;
}
.page-project-index .points-connection-box .sites-center {
  top: 226px;
  left: 456px;
  width: 374px;
}
.page-project-index .points-connection-box .workplace-integration {
  width: 347px;
  bottom: 12px;
  right: 11px;
}
.page-project-index .points-connection-box .healthy-love {
  width: 347px;
  bottom: 13px;
  left: 137px;
}
.page-project-index .button-tab .tab-list a {
  width: 160px;
}
.page-project-index .project-list {
  margin-top: 50px;
}
.page-project-detail .project-detail-banner {
  background: url('../../../../img/pc/project-detail-banner.jpg') no-repeat center;
  background-size: cover;
}
.page-project-detail .project-detail-banner .container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
}
.page-project-detail .project-detail-banner .container .title {
  font-size: 38px;
  color: #fff;
}
.page-project-detail .timeline {
  background-color: #fff;
  border-bottom: 1px solid #ededed;
  margin-bottom: 40px;
}
.page-project-detail .timeline > .container {
  position: relative;
}
.page-project-detail .timeline > .container .timeline-swiper {
  padding-top: 35px;
  padding-bottom: 25px;
  width: 100%;
}
.page-project-detail .timeline > .container .timeline-swiper:after {
  content: '';
  position: absolute;
  right: 0;
  left: 0;
  top: 95px;
  border-bottom: 1px solid #ededed;
}
.page-project-detail .timeline > .container .timeline-swiper .swiper-slide {
  position: relative;
  width: 320px;
}
.page-project-detail .timeline > .container .timeline-swiper .swiper-slide .time {
  font-size: 18px;
  color: #333;
  font-weight: bold;
  text-align: center;
  margin-bottom: 65px;
}
.page-project-detail .timeline > .container .timeline-swiper .swiper-slide .circle-outer {
  position: absolute;
  left: 50%;
  margin-left: -14px;
  top: 60px;
  margin-top: -14px;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: #dad9d9;
}
.page-project-detail .timeline > .container .timeline-swiper .swiper-slide .circle-outer .circle-inner {
  background-color: #bbb8b8;
  border-radius: 50%;
  position: absolute;
  top: 8px;
  left: 8px;
  width: 12px;
  height: 12px;
}
.page-project-detail .timeline > .container .timeline-swiper .swiper-slide .desc {
  color: #666;
  font-size: 16px;
  line-height: 1.5;
}
.page-project-detail .timeline > .container .timeline-swiper .swiper-slide:hover .time {
  color: #ebb532;
}
.page-project-detail .timeline > .container .timeline-swiper .swiper-slide:hover .circle-outer {
  background-color: #ffecbf;
}
.page-project-detail .timeline > .container .timeline-swiper .swiper-slide:hover .circle-outer .circle-inner {
  background-color: #ebb532;
}
.page-project-detail .timeline > .container .swiper-prev,
.page-project-detail .timeline > .container .swiper-next {
  font-size: 0;
  cursor: pointer;
  position: absolute;
  top: 50%;
  margin-top: -20px;
}
.page-project-detail .timeline > .container .swiper-prev.swiper-button-disabled,
.page-project-detail .timeline > .container .swiper-next.swiper-button-disabled {
  opacity: 0.3;
  cursor: default;
}
.page-project-detail .timeline > .container .swiper-prev {
  left: -60px;
}
.page-project-detail .timeline > .container .swiper-next {
  right: -60px;
}
.page-project-detail .project-content .article-content {
  padding-bottom: 20px;
}
.page-project-detail .project-content .report-wrapper {
  padding-bottom: 100px;
}
.page-project-detail .project-content .report-wrapper .report-list > li {
  margin-top: 30px;
}
.page-project-detail .project-content .report-wrapper .report-list > li:first-child {
  margin-top: 40px;
}
.page-project-detail .project-content .report-wrapper .report-list > li:hover a .content .title {
  color: #a50082;
}
.page-project-detail .project-content .report-wrapper .report-list > li a {
  height: 54px;
  display: flex;
  align-items: center;
}
.page-project-detail .project-content .report-wrapper .report-list > li a .date {
  flex-shrink: 0;
  width: 54px;
  height: 54px;
  font-size: 16px;
  display: inline-block;
  margin-right: 30px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADYAAAA2CAMAAAC7m5rvAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAArlBMVEXrtTL////+/Pj89OD67Mv45LP13J3+/PX8897668j447P12prz04Xxy27vwlfsu0H++vL889z56sT34q/12pjz0oLxymzuwVXsuT7++fD78tr56cP34az12pfy0X7wyWnuwVPsuDv9+O378Nf56cH34Kn12JTy0H3wyGbuwFHsuDnrtTPrtjP///79+Ov779T56L/236f015Hyz3vwx2Puv03stzjtvkvrtjYAAAA/vslBAAAAAWJLR0Q51wCVQAAAAAlwSFlzAAALEgAACxIB0t1+/AAAAAd0SU1FB+UDEwoADmV+CuEAAABxSURBVEjHY2AgEzCSAUa1jWob1TaqbUhpY2JmYWUjWhs7BycXNw8vHz9R5aSAoJCwiKiYuARRxauklLSMrJy8giJRpbKSsoqqmrqGphYDg7YOocJcV0/fwNDI2MTUjJQ6wNyC3NpjFIyCUTAKRgFOAADhTgtGG2TVEgAAAABJRU5ErkJggg==) no-repeat center;
  background-size: cover;
}
.page-project-detail .project-content .report-wrapper .report-list > li a .date .time,
.page-project-detail .project-content .report-wrapper .report-list > li a .date .year {
  white-space: normal;
  height: 27px;
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.page-project-detail .project-content .report-wrapper .report-list > li a .date .time {
  color: #ebb532;
}
.page-project-detail .project-content .report-wrapper .report-list > li a .date .year {
  color: #fff;
}
.page-project-detail .project-content .report-wrapper .report-list > li a .content {
  flex-grow: 1;
  height: 54px;
  border-bottom: 1px solid #ededed;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.page-project-detail .project-content .report-wrapper .report-list > li a .content .title {
  font-size: 18px;
  color: #333;
  overflow: hidden !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 500px;
  display: inline-block;
}
.page-project-detail .project-content .report-wrapper .report-list > li a .content .icon-desc-wrapper {
  width: 60px;
  flex-shrink: 0;
  color: #a50082;
  font-size: 14px;
  justify-content: flex-end;
  align-items: center;
}
.page-project-detail .project-content .report-wrapper .report-list > li a .content .icon-desc-wrapper .icon {
  width: auto;
  margin-left: 5px;
}
.page-recruit-index .recruit-list > li {
  box-shadow: 0 2px 8px 0 rgba(2, 30, 7, 0.1);
}
.page-recruit-index .recruit-list > li .img img {
  transition: all 0.3s;
}
.page-recruit-index .recruit-list > li:hover .img img {
  transform: scale(1.05);
}
.page-recruit-index .recruit-list > li:hover a .content .title {
  color: #a50082;
}
.page-recruit-index .recruit-list > li a {
  margin-top: 50px;
  padding-right: 50px;
  display: flex;
}
.page-recruit-index .recruit-list > li a .img {
  width: 320px;
  height: 224px;
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 0;
  flex-shrink: 0;
}
.page-recruit-index .recruit-list > li a .content {
  flex-grow: 1;
  margin-left: 50px;
  width: calc(100% - 320px - 224px);
}
.page-recruit-index .recruit-list > li a .content {
  padding-top: 30px;
}
.page-recruit-index .recruit-list > li a .content .time {
  font-size: 30px;
  color: #999;
  margin-bottom: 15px;
}
.page-recruit-index .recruit-list > li a .content .title {
  font-size: 20px;
  color: #333;
  margin-bottom: 20px;
  overflow: hidden !important;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.page-recruit-index .recruit-list > li a .content .desc {
  font-size: 14px;
  color: #666;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  word-break: break-all;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}
.page-info-index .financial-header {
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.page-info-index .financial-header .financial-header-item {
  color: #333;
  font-size: 18px;
}
.page-info-index .financial-header .financial-header-item .financial-header-item__text {
  color: #a50082;
  font-size: 24px;
  font-weight: bold;
}
.page-info-index .financial-header .financial-header-item + .financial-header-item {
  margin-left: 165px;
}
.page-info-index .main-wrapper {
  overflow: hidden;
}
.page-info-index .main-wrapper iframe {
  margin-top: -30px;
}
.page-info .report-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-left: -50px;
  margin-bottom: -20px;
}
.page-info .report-list > li {
  width: 196px;
  margin-left: 50px;
  margin-bottom: 20px;
}
.page-info .report-list > li .img img {
  transition: all 0.3s;
}
.page-info .report-list > li:hover .img img {
  transform: scale(1.05);
}
.page-info .report-list > li a {
  display: flex;
  flex-direction: column;
}
.page-info .report-list > li a .img {
  box-shadow: 0 2px 8px 0 rgba(2, 30, 7, 0.1);
  width: 100%;
  height: 260px;
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 0;
  flex-shrink: 0;
}
.page-info .report-list > li a .content {
  padding-top: 15px;
  height: 60px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  word-break: break-all;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  color: #333;
}
.page-en-index .en-banner {
  margin-bottom: 100px;
  min-width: 1180px;
}
.page-en-index .en-banner img {
  width: 100%;
  height: auto;
}
#YSF-BTN-HOLDER {
  opacity: 0 !important;
  pointer-events: none;
}
.btn-apply-box {
  text-align: center;
  margin-top: 20px;
}
.btn-apply-box .btn-apply {
  border-radius: 4px;
  height: 38px;
  padding: 0 20px;
  font-size: 16px;
}
body {
  display: flex;
  flex-flow: column;
  min-height: 100vh;
}
main {
  flex: 1;
  padding-top: 80px;
  display: flex;
  flex-direction: column;
}
