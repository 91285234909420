@import '../../../commonStyle/reset';
@import '../../../commonStyle/common';
@import '../../../commonStyle/var';
@import '../../../commonStyle/tool';
@import './var';
@import './icon.less';
@import './common';
@import './tool';
@import './wj';

body {
  display: flex;
  flex-flow: column;
  min-height: 100vh;
}
main {
  flex: 1;
  padding-top: @header_height;
  display: flex;
  flex-direction: column;
}

header {
}





