@import '../../../commonStyle/var';
@import '../../../commonStyle/tool';
@import './var';

.title-under-line-box{
	border-bottom: 1px solid #e5e5e5;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 20px;
	.left{
		display: inline-flex;
	}
	.title{
		display: inline-block;
		font-size: 18px;
		color: @black;
		padding: 7px 0;
		box-sizing: border-box;
		border-bottom: 3px solid @yellow;
		&.off{
			border-bottom: 0;
			color: @black;
		}
		& + .title{
			margin-left: 70px;
		}
	}
	.right{
		display: flex;
		.btn{
			padding-left: 18px;
			padding-right: 18px;
			height: 28px;
			line-height: 28px;
			font-size: 14px;
			& + .btn{
				margin-left: 18px;
			}
		}
	}
	.more{
		float: right;
		font-size: 14px;
		color: @yellow;
		line-height: 50px;
		height: 50px;
	}
}

.header{
	padding: 10px 0;
	transition: all .3s;
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	background-color: #fff;
	height: @header_height;
	z-index: 3;
	box-shadow: @box_shadow;
	&__container{
		min-width: 1080px;
		max-width: @big_container_width;
		margin-left: auto;
		margin-right: auto;
		display: flex;
		align-items: center;
		justify-content: space-between;
		.logo{
			height: 60px;
			width: auto;
		}
		.header-right{
			position: relative;
			display: flex;
			align-items: center;
			.header-right__left{
				display: flex;
				align-items: center;
				.nav-list{
					display: flex;
					align-items: center;
					font-size: 16px;
					>li{
						position: relative;
						&.donate-item{
							a{
								color: #fff;
								&:hover{
									color: #fff;
								}
							}
							&:after{
								display: none;
							}
						}
						.btn{
							padding: 0 14px;
							height: 32px;
							font-size: 14px;
						}
						&:after{
							content: '';
							height: 40px;
							position: absolute;
							left: 0;
							top: 17px;
							width: 100%;
							background-color: transparent;
						}
						& + li{
							margin-left: 35px;
						}
						>a{
							color: @black;
							padding-left: 5px;
						}
						&.active,&.now{
							>a{
								color: @yellow;
							}
						}
						.submenu{
							font-size: 14px;
							display: none;
							position: absolute;
							left: 0;
							top: 50px;
							width: 180px;
							background-color: #fff;
							box-shadow: @box_shadow;
							border-bottom: 4px solid @yellow;
							>li{
								a{
									height: 45px;
									line-height: 45px;
									padding-left: 25px;
									display: block;
									color: @light-black;
								}
								&:hover, &.active{
									a{
										color: @yellow;
									}
								}
							}
						}
					}
				}
				.search-wrapper{
					margin-left: 50px;
					display: inline-flex;
				}
			}
			.header-right__right{
				position: absolute;
				right: -120px;
				top: 50%;
				margin-top: -11px;
				.language-box{
					display: flex;
					align-items: center;
					margin-left: 50px;
					flex-shrink: 0;
					white-space: nowrap;
					.lang-item{
						color: @light-black;
						position: relative;
						cursor: pointer;
						& + .lang-item{
							margin-left: 8px;
							padding-left: 8px;
							&:before{
								content: '';
								position: absolute;
								left: 0;
								top: 4px;
								height: 12px;
								border-left: 1px solid @light-black;
							}
						}
						&.active{
							cursor: default;
							color: @purple;
						}
					}
				}
			}
		}

	}
}

.footer{
	.footer-top{
		.bg(bg-index-footer, jpg);
		height: 380px;
		display: flex;
		align-items: center;
		.big-container{
			padding-left: 15px;
			padding-right: 15px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			min-width: @container_width;

			.footer-top__item{
				& + .footer-top__item{
					margin-left: 30px;
				}
				flex-grow: 1;
				flex-shrink: 0;
				border-radius: 14px;
				height: 240px;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				width: 640px;
				color: #fff;
				.title{
					word-break: break-word;
					margin-bottom: 18px;
					font-size: 26px;
				}
				.desc{
					word-break: break-word;
					margin-bottom: 23px;
					font-size: 16px;
					max-width: 420px;
					text-align: center;
				}
				.btn-area{
					display: flex;
					align-items: center;
					.btn{
						font-size: 14px;
						height: 38px;
						padding: 0 25px;
						background-color: transparent;
						color: #fff;
						& + .btn{
							margin-left: 20px;
						}
					}
				}

				&.month-pay{
					.bg(bg-footer-month-pay,png);
					.btn{
						&:hover{
							background-color: #fff;
							color: @yellow;
						}
					}
				}
				&.join-us{
					.bg(bg-footer-join-us,png);
					.btn{
						&:hover{
							background-color: #fff;
							color: @green;
						}
					}
				}
			}
		}
	}
	.footer-content{
		background-color: #cea950;
		font-size: 14px;
		.container{
			height: 80px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			color: #fff;
			.keep-record{
				margin-left: 50px;
				display: inline-block;
			}
			a{
				color: #fff;
			}
		}
	}
	&.footer-en{
		.big-container{
			.footer-top__item{
				.btn-area{
					min-width: 170px;
				}
			}
		}
	}
}

@media screen and (max-width: 1670px){
	.header{
		&__container{
			.header-right{
				.header-right__left{
					.search-wrapper{
						margin-left: 30px;
					}
				}
				.header-right__right{
					position: static;
					right: unset;
					margin-top: 0;
					.language-box{
						margin-left: 30px;
					}
				}
			}

		}
	}
}

@media screen and (max-width: 1450px){
	.header{
		&__container{
			padding-left: 15px;
			padding-right: 15px;
		}
	}
	.footer{
		.footer-top{
			.big-container{
				padding-left: 15px;
				padding-right: 15px;
				.footer-top__item{
					width: 580px;
				}
			}
		}
	}
}

@media screen and (max-width: 1180px){
	.header{
		&__container{
			padding-left: 15px;
			padding-right: 15px;
			.header-right{
				.header-right__left{
					.nav-list{
						>li{
							& + li{
								margin-left: 25px;
							}
						}
					}
					.search-wrapper{
						margin-left: 20px;
					}
				}
				.header-right__right{
					.language-box{
						margin-left: 20px;
					}
				}
			}
		}
	}
	.footer{
		.footer-top{
			.big-container{
				.footer-top__item{
					width: 520px;
				}
			}
		}
	}
}

.banner{
	height: 300px;
	color: #fff;
	.bg(about-banner, jpg);
	.container{
		height: 100%;
		position: relative;
		.title-wrapper{
			position: absolute;
			top: 50%;
			right: 0;
			text-shadow: 0 2px 3px rgba(35, 34, 3, 0.26);
			text-align: right;
			height: 86px;
			margin-top: -43px;
			.title-ch{
				font-size: 42px;
			}
			.title-en{
				font-size: 18px;
			}
		}
	}
}

.content-tab{
	padding-left: 40px;
	padding-right: 40px;
	font-size: 18px;
	display: flex;
	align-items: center;
	justify-content: space-around;
	height: 100px;
	box-shadow: @box_shadow;
	border-radius: 4px;
	background-color: #fff;
	>li{
		position: relative;
		a{
			color: @black;
			&:hover{
				color: @purple;
			}
		}
		&.active{
			position: relative;
			&:after{
				content: '';
				height: 2px;
				position: absolute;
				left: 0;
				right: 0;
				bottom: -15px;
				.bg(border-bottom-color, jpg);
			}
		}
	}
	&.banner-content-tab{
		margin-top: -50px;
		margin-bottom: 80px;
		&.banner-content-tab-2{
			padding-left: 260px;
			padding-right: 260px;
		}
		&.banner-content-tab-3{
			padding-left: 160px;
			padding-right: 160px;
		}
		&.banner-content-tab-4{
			padding-left: 110px;
			padding-right: 110px;
		}
	}
}

.page-index{
	.index-swiper{
		width: 100%;
		min-width: @container_width;
		max-width: @max_container_width;
		margin-left: auto;
		margin-right: auto;
		position: relative;
		.swiper-pagination{
			position: absolute;
			bottom: 30px;
			left: 50%;
			transform: translateX(-50%);
			.swiper-pagination-bullet{
				width: 100px;
				height: 3px;
				border-radius: 0;
				background-color: #fff;
				opacity: .5;
				position: relative;
				& + .swiper-pagination-bullet{
					margin-left: 25px;
				}
				&.swiper-pagination-bullet-active{
					opacity: 1;
				}
			}
		}
		.swiper-prev, .swiper-next{
			font-size: 0;
			position: absolute;
			top: 50%;
			z-index: 2;
			cursor: pointer;
			margin-top: -20px;
		}
		.swiper-prev{
			left: 50px;
		}
		.swiper-next{
			right: 50px;
		}
	}

	.news-project-box{
		.content-block__content{
			display: flex;
			align-items: center;
			justify-content: space-between;

			.news-project-box-col{
				height: 444px;
				position: relative;
			}
			.news-project-box__left{
				width: 770px;
				display: inline-flex;
				align-items: center;
				justify-content: space-between;
				.news-project-box__left-item{
					width: 366px;
					display: flex;
					flex-direction: column;
					transition: all .3s;
					.img-scale;
					box-shadow: @box_shadow;
					overflow: hidden;
					&:after{
						content: '';
						position: absolute;
						bottom: 0;
						left: 0;
						width: 100%;
						height: 10px;
						transform: translateY(5px);
						transition: all .3s;
					}
					&:first-child{
						&:hover{
							.content{
								.title{
									color: @yellow;
								}
							}
						}
						&:after{
							background-color: #dab866;
						}
					}
					&:nth-child(2){
						&:hover{
							.content{
								.title{
									color: @green;
								}
							}
						}
						&:after{
							background-color: @green;
						}
					}
					&:hover{
						.content{
							.desc{
								color: @black;
							}
						}
						&:after{
							transform: translateY(0);
						}
					}
					.img{
						.img-wrap(100%, 258px);
					}
					.content{
						flex-grow: 1;
						padding: 20px;
						.time{
							font-size: 30px;
							color: @gray;
							margin-bottom: 4px;
						}
						.title{
							color: @black;
							font-size: 20px;
							margin-bottom: 10px;
							.text-overflow;
							line-height: 1.5;
						}
						.desc{
							font-size: 14px;
							.text-limit2;
							color: @light-black;
							line-height: 1.8;
						}
					}

				}
			}
			.news-project-box__right{
				.news-project-box__right-item{
					position: relative;
					display: flex;
					width: 363px;
					.img-scale;
					& + .news-project-box__right-item{
						margin-top: 21px;
					}
					.tag{

					}
					.img{
						.img-wrap(100%, 211px);
					}
					.desc{
						position: absolute;
						left: 20px;
						right: 20px;
						bottom: 10px;
						color: #fff;
						.text-limit2;
					}
				}
			}
		}
	}

	.data-panel{
		.bg(bg-index-data, jpg);
		height: 605px;
		.data-panel__top{
			padding-top: 60px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 50px;
			.data-panel__top-left{
				display: flex;
				align-items: center;
				.data-panel-list{
					.total-num{
						width: 490px;
					}
					.total-person{
					}
				}
			}
			.data-panel__top-right{
				display: flex;
				align-items: center;
				.btn{

					& + .btn{
						margin-left: 20px;
					}
				}
			}
		}
		.data-panel__bottom{
			display: flex;
			align-items: flex-start;
			justify-content: space-between;
			.main-wrapper{
				overflow: hidden;
				height: 248px;
				position: relative;
				margin-top: -13px;
				&:before{
					content: '';
					position: absolute;
					left: 0;
					top: 0;
					width: 100%;
					z-index: 1;
					background-color: #fff;
					height: 26px;
				}
			}
			.data-panel__bottom-item{
				background-color: #fff;
				width: 565px;
				max-height: 342px;
				padding: 35px 20px 20px;
				.tab-list{
					position: relative;
					z-index: 2;
					font-size: 20px;
					li{
						cursor: pointer;
						display: inline-block;
						& + li{
							margin-left: 50px;
						}
						a{
							color: @light-black;
						}
						&.active{
							color: @purple;
							padding-bottom: 5px;
							border-bottom: 1px solid @purple;
							a{
								color: @purple;
							}
						}
					}
				}
				.donate-slide-wrapper{
					overflow: hidden;
					height: 235px;
					padding-top: 15px;
					.donate-slide{
						overflow: hidden;
						font-size: 14px;
						color: @black;
						.swiper-slide{
							display: flex;
							align-items: center;
							height: 35px;
							.user-name{
								width: 115px;
								padding-right: 15px;
								.text-overflow;
							}
							.project-name{
								width: 230px;
								padding-right: 55px;
								.text-overflow;
							}
							.donate-count{
								.text-overflow;
								width: 103px;
								padding-right: 15px;
							}
							.donate-time{
								.text-overflow;
							}
						}
					}
				}
				.data-panel-data-list{
					font-size: 14px;
					padding-top: 15px;
					>li{
						height: 44px;
						display: flex;
						align-items: center;
						&:hover{
							a{
								.title{
									color: @yellow;
								}
							}
						}
						a{
							width: 100%;
							display: flex;
							align-items: center;
							justify-content: space-between;
							color: @black;
						}
					}
				}
			}
		}
	}

	.welfare-project-box{
		.welfare-project-list{
			display: flex;
			align-items: center;
			>li{
				.img-scale;
				& + li{
					margin-left: 1px;
				}
				a{
					.img-wrap(393px, 277px);
					color: #fff;
					display: flex;
					position: relative;
					.title{
						position: absolute;
						right: 0;
						left: 0;
						bottom: 20px;
						text-align: center;
						font-size: 20px;
						padding-left: 10%;
						padding-right: 10%;
						.text-overflow;
						font-weight: bold;
					}
					&:hover{
						.content-box{
							opacity: 1;
						}
					}
					.content-box{
						transition: all .3s;
						opacity: 0;
						position: absolute;
						left: 0;
						top: 0;
						right: 0;
						bottom: 0;
						padding-top: 40px;
						background-color: #ecb530;
						.content-title{
							font-size: 24px;
							font-weight: bold;
							margin-bottom: 27px;
							text-align: center;
							&:before,&:after{
								content: '';
								background-color: #fff;
								width: 16px;
								height: 2px;
								display: inline-block;
								vertical-align: middle;
							}
						}
						.desc{
							font-size: 16px;
							line-height: 1.8;
							.text-limit3;
							padding-left: 25px;
							padding-right: 25px;
							margin-bottom: 20px;
						}
						.to-box{
							position: absolute;
							left: 50%;
							bottom: 25px;
							margin-left: -19px;
							border-radius: 50%;
							border: 1px solid #fff;
							width: 38px;
							height: 38px;
							display: flex;
							align-items: center;
							justify-content: center;
							i{
								transition: all .3s;
								transition-delay: .1s;
							}
							&:hover{
								i{
									transform: translateX(3px);
								}
							}
						}
					}
				}
			}
		}
	}

	.map-panel{
		//height: 775px;//6.21隐藏地图
		//.bg(bg-index-map, jpg);//6.21隐藏地图
		background-color: #e3c06e;//6.21隐藏地图
		height: 190px;//6.21隐藏地图
		overflow: hidden;//6.21隐藏地图

		padding-top: 50px;
		position: relative;
		z-index: 1;
		.data-panel-list{
			display: flex;
			align-items: center;
			justify-content: space-between;
			color: #fff;
			.item{
				.data-count{
					.flex-center;
					font-size: 42px;
					.number{
						font-size: 42px;
					}
					.num-overflow{
						margin-left: 10px;
						font-weight: bold;
					}
				}
				.desc{
					font-size: 16px;
					text-align: center;
				}
			}
		}
		.echart-wrapper{
			margin-top: 45px;
			position: relative;
			margin-left: auto;
			margin-right: auto;
			.province-data-panel{
				display: none;
				background-color: #fff;
				padding: 25px 30px;
				border-radius: 4px;
				box-shadow: @box_shadow;
				position: absolute;
				left: 0;
				top: 0;
				z-index: 2;
				transition: top .3s, left .3s;
				.province-name{
					font-size: 20px;
					color: @black;
					white-space: nowrap;
				}
				.province-data-item{
					font-size: 16px;
					white-space: nowrap;
					display: flex;
					align-items: center;
					margin-top: 5px;
					.province-data-title{
						color: @light-black;
					}
					.province-data{
						color: @blue;
					}

				}
			}
			#echart{
				width: 740px;
				height: 540px;
				.margin-center;
				>div{
					pointer-events: unset !important;
					.echart-data-box{
						width: 380px;
						border-radius: 4px;
						background-color: #fff;
						border-top: 5px solid #ef8007;
						padding: 30px;
						.province-name{
							font-size: 24px;
							color: #ef8007;
						}
						.item{
							padding-top: 25px;
							padding-bottom: 25px;
							border-bottom: 1px solid @border;
							.title{
								color: @black;
								font-size: 18px;
								margin-bottom: 10px;
								line-height: 1.5;
							}
							.echart-activity-list{
								font-size: 16px;
								>li{
									padding-left: 20px;
									position: relative;
									&:before{
										content: '';
										position: absolute;
										left: 0;
										top: 10px;
										width: 4px;
										height: 4px;
										background-color: @light-black;
										border-radius: 50%;
									}
									& + li{
										margin-top: 5px;
									}
									a{
										display: block;
										line-height: 1.5;
										color: @light-black;
									}
								}
							}
						}
					}
				}
			}
		}
	}

	.home-partner-swiper{
		margin: 0 -5px;
		width: 100%;
		.swiper-slide{
			font-size: 0;
			height: 120px;
			padding: 20px 15px;
			.flex-center;
			a,span{
				height: 80px;
				.flex-center;
				img{
					max-width: 100%;
					max-height: 100%;
				}
			}
		}
	}

}

.floating-wrapper{
	position: absolute;
	right: 0;
	top: 55%;
	z-index: 3;
	.float-list{
		>li{
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			a{
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
			}
			background-color: #e6e6e6;
			width: 52px;
			height: 52px;
			transition: all .3s;
			font-size: 14px;
			position: relative;
			.icon-wrapper{
				font-size: 0;
				margin-bottom: .05rem;
				.icon{
					&:first-child{
						display: none;
					}
					&:last-child{
						display: inline-block;
					}
				}
			}
			.text{
				color: @black;
			}
			&:hover,&.active{
				background-color: @yellow;
				.icon{
					&:first-child{
						display: inline-block;
					}
					&:last-child{
						display: none;
					}
				}
				.text{
					color: #fff;
				}
			}
			& + li{
				margin-top: 1px;
			}

			&.qr-code-box{
				&:hover{
					.qr-code{
						display: block;
					}
				}
				.qr-code{
					box-shadow: @box_shadow;
					position: absolute;
					right: 100%;
					top: 0;
					padding: 20px;
					display: none;
					background-color: #fff;
					width: 130px;
					height: 157px;
					.img{
						.img-wrap(90px, 90px);
					}
					.desc{
						margin-top: 20px;
						color: @purple;
						font-size: 12px;
						text-align: center;
					}
				}
			}
		}
	}
}

.page-service-index{
	.column-panel{
		.column-left{
			.service-content{
				.img-scale;
				display: flex;
				padding-top: 30px;
				padding-bottom: 30px;
				.img{
					.img-wrap(300px, 212px);
				}
				.content{
					max-width: 460px;
					padding-top: 13px;
					margin-left: 35px;
					flex-shrink: 0;
					.title{
						margin-bottom: 25px;
						font-size: 24px;
						color: @black;
					}
					.icon-desc-wrapper{
						margin-bottom: 20px;
						.desc{
							.text-limit5;
						}
					}
				}
			}
			.article-content{
				padding-bottom: 20px;
			}
			.activity-list-wrapper{
				padding-bottom: 60px;
				.load-more{
					margin-top: 50px;
				}
			}
		}
	}
}

.page-search-index{
	.meta{
		background-color: #dab866;
		height: 200px;
		padding-top: 50px;
		margin-bottom: 40px;
		.title{
			text-align: center;
			font-size: 24px;
			color: #fff;
			margin-bottom: 15px;
		}
		.search-form{
		}
	}
	.no-search-data{
		color: @light-black;
		font-size: 14px;
		margin-bottom: 60px;
	}
	.search-list{
		li{
			a{
				border-bottom: 1px solid @border;
				padding: 25px 30px;
				display: block;
				transition: all .3s;
				border-radius: 4px;
				&:hover{
					box-shadow: @box_shadow;
					border-bottom: 1px solid transparent;
					.title{
						color: @purple;
					}
				}
				.title{
					color: @black;
					font-size: 18px;
					margin-bottom: 15px;
					.text-overflow;
				}
				.time{
					color: @gray;
					font-size: 14px;
					margin-bottom: 15px;
				}
				.desc{
					color: @light-black;
					font-size: 14px;
					.text-limit2;
				}
			}
		}
	}
}

.page-about-org{
	.button-tab{
		margin-top: 70px;
	}
	.team-list{
		display: flex;
		flex-wrap: wrap;
		margin-left: -50px;
		margin-bottom: -50px;
		>li{
			margin-left: 50px;
			margin-bottom: 50px;
			width: 195px;
			box-shadow: @box_shadow;
			display: flex;
			flex-direction: column;
			position: relative;
			overflow: hidden;
			&:after{
				content: '';
				transition: all .3s;
				position: absolute;
				bottom: 0;
				left: 0;
				width: 0;
				height: 2px;
				border-bottom: 2px solid #dab866;
			}
			&:hover{
				&:after{
					width: 100%;
				}
			}
			.img{
				.img-wrap(100%, 145px);
			}
			.content{
				padding: 18px 15px;
				display: flex;
				justify-content: center;
				flex-direction: column;
				.top{
					display: flex;
					align-items: baseline;
					margin-bottom: 6px;
					.name{
						color: @black;
						font-size: 16px;
						flex-shrink: 0;
					}
					.position{
						margin-left: 15px;
						color: @light-black;
						font-size: 14px;
						.text-overflow;
					}
				}
				.bottom{
					color: @light-black;
					font-size: 14px;
				}
			}
		}
	}
}

.page-about-honor{
	.honor-list{
		display: flex;
		flex-wrap: wrap;
		margin-left: -50px;
		margin-bottom: -50px;
		>li{
			margin-left: 50px;
			margin-bottom: 50px;
			box-shadow: @box_shadow;
			font-size: 16px;
			width: 257px;
			color: @black;
			.img-scale;
			cursor: pointer;
			display: flex;
			flex-direction: column;
			.img{
				.img-wrap(100%, 181px);
			}
			.content{
				font-size: 16px;
				align-items: center;
				justify-content: center;
				padding: 20px 15px;
				line-height: 1.6;
				.flex-center;
			}
			&:hover{
				color: @purple;
			}
		}
	}
}

@keyframes location_jump {
	0% {transform: translateY(0);}
	25% {transform: translateY(-5px);}
	100% {transform: translateY(0);}
}

.page-about-contact{
	.contact-box{
		display: flex;
		align-items: center;
		height: 520px;
		.map{
			width: 694px;
			flex-shrink: 0;
			position: relative;
			#mapBox{
				height: 520px;
				.BMap_Marker{
					animation: location_jump 1s infinite;
					animation-direction: alternate;
				}
				img{
					max-width: unset !important;
					max-height: unset !important;
				}
			}
		}
		.content{
			color: #fff;
			flex-grow: 1;
			padding-top: 55px;
			padding-left: 30px;
			padding-right: 30px;
			height: 100%;
			font-size: 16px;
			.bg(bg-contact,jpg);
			.content-list{
				>li{
					margin-bottom: 20px;
				}
			}
			.qr-code{
				.img-wrap(160px, 160px);
				padding: 10px;
				background-color: #fff;
			}
		}
	}
}

.page-news-index{

}

.page-project-index{
	.project-index-header{
		color: #fff;
		padding-top: 90px;
		text-align: center;
		background-color: #dab866;
		padding-bottom: 110px;
		margin-bottom: 100px;
		padding-left: 30px;
		padding-right: 30px;
		.title{
			font-size: 32px;
			margin-bottom: 35px;
		}
		.desc{
			font-size: 18px;
			line-height: 1.8;
		}

	}
	.points-connection-box{
		padding-top: 130px;
		margin-top: 30px;
		position: relative;
		transform: translateX(-64px);
		.bg-points-connection{
			width: 750px;
			display: block;
			margin-left: auto;
			margin-right: auto;
		}
		.points-connection-item{
			position: absolute;
			&.points-connection-item-hover{
				&:hover{
					.points-connection-item-img{
						transform: translateY(-10px);
					}
				}
			}
		}
		.points-connection-item-img{
			transition: all 1s;
			position: relative;
			z-index: 2;
			width: 100%;
		}
		.bg-points-connection-item{
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 1;
		}
		.site-project{
			width: 280px;
			top: -18px;
			left: 505px;
		}
		.sites-center{
			top: 226px;
			left: 456px;
			width: 374px;
		}
		.workplace-integration{
			width: 347px;
			bottom: 12px;
			right: 11px;
		}
		.healthy-love{
			width: 347px;
			bottom: 13px;
			left: 137px;

		}
	}
	.button-tab{
		.tab-list{
			a{
				width: 160px;
			}
		}
	}
	.project-list{
		margin-top: 50px;
	}
}

.page-project-detail{
	.project-detail-banner{
		.bg(project-detail-banner, jpg);
		.container{
			.flex-center;
			height: 300px;
			.title{
				font-size: 38px;
				color: #fff;
			}
		}
	}
	.timeline{
		background-color: #fff;
		border-bottom: 1px solid @border;
		margin-bottom: 40px;
		>.container{
			position: relative;
			.timeline-swiper{
				padding-top: 35px;
				padding-bottom: 25px;
				width: 100%;
				&:after{
					content: '';
					position: absolute;
					right: 0;
					left: 0;
					top: 95px;
					border-bottom: 1px solid @border;
				}
				.swiper-slide{
					position: relative;
					width: 320px;
					.time{
						font-size: 18px;
						color: @black;
						font-weight: bold;
						text-align: center;
						margin-bottom: 65px;
					}
					.circle-outer{
						position: absolute;
						left: 50%;
						margin-left: -14px;
						top: 60px;
						margin-top: -14px;
						width: 28px;
						height: 28px;
						border-radius: 50%;
						background-color: #dad9d9;
						.circle-inner{
							background-color: #bbb8b8;
							border-radius: 50%;
							position: absolute;
							top: 8px;
							left: 8px;
							width: 12px;
							height: 12px;
						}
					}
					.desc{
						color: @light-black;
						font-size: 16px;
						line-height: 1.5;
					}
					&:hover{
						.time{
							color: @yellow;
						}
						.circle-outer{
							background-color: #ffecbf;
							.circle-inner{
								background-color: #ebb532;
							}
						}
					}
				}
			}
			.swiper-prev,.swiper-next{
				font-size: 0;
				cursor: pointer;
				position: absolute;
				top: 50%;
				margin-top: -20px;
				&.swiper-button-disabled{
					opacity: .3;
					cursor: default;
				}
			}
			.swiper-prev{
				left: -60px;
			}
			.swiper-next{
				right: -60px;
			}
		}
	}
	.project-content{
		.article-content{
			padding-bottom: 20px;
		}
		.report-wrapper{
			padding-bottom: 100px;
			.report-list{
				>li{
					margin-top: 30px;
					&:first-child{
						margin-top: 40px;
					}
					&:hover{
						a{
							.content{
								.title{
									color: @purple;
								}
							}
						}
					}
					a{
						height: 54px;
						display: flex;
						align-items: center;
						.date{
							flex-shrink: 0;
							width: 54px;
							height: 54px;
							font-size: 16px;
							display: inline-block;
							margin-right: 30px;
							.bg(icon-report-download-date, png);
							.time,.year{
								white-space: normal;
								height: 27px;
								width: 100%;
								display: inline-flex;
								align-items: center;
								justify-content: center;
							}
							.time{
								color: @yellow;
							}
							.year{
								color: #fff;
							}
						}
						.content{
							flex-grow: 1;
							height: 54px;
							border-bottom: 1px solid @border;
							display: flex;
							align-items: center;
							justify-content: space-between;
							.title{
								font-size: 18px;
								color: @black;
								.text-overflow;
								max-width: 500px;
								display: inline-block;
							}
							.icon-desc-wrapper{
								width: 60px;
								flex-shrink: 0;
								color: @purple;
								font-size: 14px;
								justify-content: flex-end;
								align-items: center;
								.icon{
									width: auto;
									margin-left: 5px;
								}
							}
						}
					}
				}
			}
		}
	}
}

.page-recruit-index{
	.recruit-list{
		>li{
			.img-scale;
			box-shadow: @box_shadow;
			&:hover{
				a{
					.content{
						.title{
							color: @purple;
						}
					}
				}
			}
			a{
				margin-top: 50px;
				padding-right: 50px;
				.item(320px, 224px, 50px);
				.content{
					padding-top: 30px;
					.time{
						font-size: 30px;
						color: @gray;
						margin-bottom: 15px;
					}
					.title{
						font-size: 20px;
						color: @black;
						margin-bottom: 20px;
						.text-overflow;
					}
					.desc{
						font-size: 14px;
						color: @light-black;
						.text-limit2;
					}
				}
			}
		}
	}
}

.page-info-index{
	.financial-header{
		margin-bottom: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		.financial-header-item{
			color: @black;
			font-size: 18px;
			.financial-header-item__text{
				color: @purple;
				font-size: 24px;
				font-weight: bold;
			}
			& + .financial-header-item{
				margin-left: 165px;
			}
		}
	}
	.main-wrapper{
		overflow: hidden;
		iframe{
			margin-top: -30px;
		}
	}
}

.page-info{
	.report-list{
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		margin-left: -50px;
		margin-bottom: -20px;
		>li{
			.img-scale;
			width: 196px;
			margin-left: 50px;
			margin-bottom: 20px;
			a{
				display: flex;
				flex-direction: column;
				.img{
					box-shadow: @box_shadow;
					.img-wrap(100%, 260px);
				}
				.content{
					padding-top: 15px;
					height: 60px;
					.text-limit2;
					color: @black;
				}
			}
		}
	}
}

.page-en-index{
	.en-banner{
		margin-bottom: 100px;
		min-width: @container_width;
		img{
			width: 100%;
			height: auto;
		}
	}
}

#YSF-BTN-HOLDER{
	opacity: 0 !important;
	pointer-events: none;
}

.btn-apply-box{
	text-align: center;
	margin-top: 20px;
	.btn-apply{
		border-radius: 4px;
		height: 38px;
		padding: 0 20px;
		font-size: 16px;
	}
}

