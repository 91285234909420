@import "./var";

.iconGen(@icon,@width:10px,@height:10px){
  background: url("@{icon_url}@{icon}.png") no-repeat center;
  width: @width;
  height: @height;
  background-origin:content-box;
  background-size: contain;
  display: inline-block;
}


.icon-swiper-prev{
  .iconGen(icon-swiper-prev,21px,40px);
}

.icon-swiper-next{
  .icon-swiper-prev;
  transform: rotateZ(180deg);
}

.icon-search-main{
  .iconGen(icon-search-main,22px,22px);
}

.icon-search-main{
  .iconGen(icon-search-main,22px,22px);
}

.icon-news-activity{
  .iconGen(icon-news-activity,32px,30px);
}

.icon-welfare-project{
  .iconGen(icon-welfare-project,30px,30px);
}

.icon-right-arrow{
  .iconGen(icon-right-arrow,15px,10px);
}

.icon-heart-gray{
  .iconGen(icon-heart-gray,16px,14px);
}

.icon-heart-white{
  .iconGen(icon-heart-white,16px,14px);
}

.icon-call-white{
  .iconGen(icon-call-white,16px,16px);
}

.icon-call-gray{
  .iconGen(icon-call-gray,16px,16px);
}

.icon-top-arrow-gray{
  .iconGen(icon-top-arrow-gray,16px,8px);
}

.icon-top-arrow-white{
  .iconGen(icon-top-arrow-white,16px,8px);
}

.icon-clock-gray{
  .iconGen(icon-clock-gray,18px,18px);
}

.icon-people-gray{
  .iconGen(icon-people-gray,15px,16px);
}

.icon-eye-gray{
  .iconGen(icon-eye-gray,19px,14px);
}

.icon-prev-left{
  .iconGen(icon-prev-left,21px,40px);
}

.icon-prev-right{
  .icon-prev-left;
  transform: rotate(180deg);
}

.icon-download-purple{
  .iconGen(icon-download-purple,13px,13px);
}

.icon-play-white{
  .iconGen(icon-play-white,28px,28px);
}

.icon-co-partner{
  .iconGen(icon-co-partner,38px,32px);
}

.icon-close-image,.icon-close{
  .iconGen(icon-close-image,42px,42px);
}


.icon-map-data{
  .iconGen(icon-map-data,18px,23px);
}

.icon-page-prev{
  .iconGen(icon-page-prev,8px,14px);
}

.icon-page-next{
  .icon-page-prev;
  transform: rotate(180deg);
}
