@import '../../../commonStyle/var';
@import '../../../commonStyle/tool';
@import './var';

.btn {
  &.btn-s {
    padding: 0 10px;
    height: 24px;
    font-size: 14px;
    min-width: 80px;
  }
  &.btn-m {
    padding-left: 30px;
    padding-right: 30px;
    height: 45px;
    font-size: 18px;
  }
  &.btn-b{
  }

  &.btn-shadow{
    box-shadow: @box_shadow;
  }
}
.big-container {
  width: @big_container_width;
  margin: 0 auto;
}
.container {
  width: @container_width;
  margin: 0 auto;
}

.small-container{
  width: @small_container_width;
  margin: 0 auto;
}

.active-item{
  border-bottom: 2px solid;
  border-image: url("@{image_url}/border-bottom-color.jpg") 100% 0 30 stretch;
}


textarea{
  padding: 10px;
}


//列表页码
.pagination-box {
  color: @gray;
  margin-bottom: 20px;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  .pagination-btn {
    width: 64px;
    height: 24px;
    color: #808080;
    font-size: 14px;
  }
  .prev {
  }
  .next {
  }
  .page-count {
    font-size: 14px;
    color: @light-black;
    > .input {
      width: 34px;
      height: 24px;
      border-radius: 4px;
      border: solid 1px @border;
      padding: 0 4px;
      margin-right: 6px;
      margin-left: 9px;
    }
    > .input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none !important;
    }
    > .input[type='number'] {
      -moz-appearance: textfield;
    }
    > .confirm {
      margin-left: 12px;
      color: @blue;
      font-size: 14px;
      display: inline-block;
      padding: 0 8px;
      border-radius: 4px;
      border: 1px solid @border;
    }
  }
  .n-list {
    .n {
      color: @light-black;
      font-size: 16px;
      margin-right: 14px;
      border-radius: 50%;
      width: 33px;
      height: 33px;
      display: inline-block;
      text-align: center;
      line-height: 33px;
      &:last-child {
        margin-right: 0;
      }
      &.current, &:hover {
        color: #fff;
        background-color: @yellow;
      }
    }
  }
}

//popup-reset start
.popup-wrapper{
  padding: 40px 35px 35px;
  .icon-close{
    right: 30px;
    top: -65px;
  }
  .popup-header{
    padding: 13px 0 10px;
    h3.title{
      span{
        height: 43px;
        line-height: 40px;
        border-bottom-color: @yellow;
      }
    }
  }
  .popup-content{
    padding-left: 0;
    padding-right: 0;
  }
  &.popup-wrapper-420{
    width: 420px;
    padding: 0 20px 70px 20px;
    .form-group{
      margin-bottom: 7px;
      .field-name{
        width: 95px;
      }
    }
    .form-btn-group{
      margin-top: 40px;
      padding-left: 95px;
      .btn{
        padding: 0;
        width: 80px;
        height: 28px;
        font-size: 14px;
        & + .btn{
          margin-left: 50px;
        }
      }
    }
  }
}
//popup-reset end
#video-popup{
  .popup-wrapper{
    margin: 0;
    padding: 0;
  }
  .popup-content{
    margin: 0;
    padding: 0;
    overflow: visible !important;
    .video-js{
      width: 100%;
      text-align: center;
    }
    iframe{
      width: 100%;
      height: 500px;
    }
  }
}



div.msgBox {
  .msgBoxTitle {
    font-size: 16px;
    color: #19191a;
    padding-bottom: 10px;
    font-weight: bold;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 8%;
      height: 3px;
      background-color: @blue;
    }
  }
  .msgBoxContent {
    margin: 0;
    padding: 20px 0;
    width: 100%;
    text-align: center;
    span {
    }
  }
  .msgBoxButtons {
    input[type='button'] {
      margin-left: 20px;
      height: 34px;
      line-height: 34px;
      width: 120px;
      color: #fff;
      border: 0;
      display: inline-block;
      &:first-child{
        margin-left: 0;
        .btn.btn-blue.btn-border;
        .btn.btn-radius;
      }
      &:last-child{
        .btn.btn-blue;
        .btn.btn-radius;
      }
    }
  }
}


.title-bar{
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .title-wrapper{
    display: flex;
    align-items: center;
  }
  .icon-wrapper{
    display: flex;
    align-items: center;
    .icon{
      width: 35px;
    }
  }
  &__title{
    font-size: 22px;
    color: @black;
  }
  &__more{
    font-size: 14px;
    color: @gray;
  }
}


.tag{
  padding-left: 10px;
  padding-right: 10px;
  height: 34px;
  font-size: 14px;
}

.btn-group{
  &.btn2{
    .btn{
      width: 130px;
    }
  }
  .btn{
    margin-left: 30px;
  }
}


.img-list{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-left: -20px;
  margin-bottom: -15px;
  >li{
    cursor: pointer;
    border-radius: 4px;
    margin-bottom: 15px;
    .img-wrap(180px, 116px);
    margin-left: 20px;
  }
  &.auto-size{
    >li{
      width: auto;
      height: auto;
    }
  }
}

.page-panel{
  background-color: #fff;
  border-radius: 4px;
  padding: 20px 20px 60px;
}

.form-block{
  padding-bottom: 20px;
  border-bottom: 1px solid @border;
  margin-bottom: 30px;
}

.input-unit{
  input{
  }
  .unit{
    font-size: 14px;
    width: 30px;
  }
}

.summary-list {
  > li {
    padding: 0 20px;
    &:first-child{
      padding-left: 0;
    }
    &:last-child{
      padding-right: 0;
    }
    .summary-list__title {
      font-size: 14px;
    }

    .summary-list__desc {
      font-size: 18px;
    }

    & + li {
      border-left: 1px solid @border;
    }
  }

  &.summary-list-reverse {
    > li {

      .summary-list__title {
      }

      .summary-list__desc {
      }
    }
  }
}

.title-block{
  text-align: center;
  margin-bottom: 50px;
  &__title{
    color: @black;
    font-size: 30px;
  }
  &__desc{
    margin-top: 4px;
    color: @gray;
    font-size: 18px;
  }
}

.form-group{
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  &__multi{
    align-items: flex-start;
  }
  .field-name{
    width: 120px;
    flex-shrink: 0;
    font-size: 14px;
    color: @black;
    line-height: 1.5;
    display: flex;
    align-items: baseline;
    justify-content: flex-end;
    padding-right: 15px;

    .sub-field-name{
      font-size: 12px;
      line-height: 1.2;
      color: @gray;
      margin-left: 5px;
    }
    .field-require-text{
    }
  }
  .form-field{
    flex-grow: 1;
    input[type=text],input[type=password],input[type=number],input[type=email],textarea,select{
      width: 100%;
      background-color: #fafafa;
      border: 1px solid @border;
      transition: border-color .3s;
      &:focus{
        border-color: @blue;
      }
    }
    textarea{
      display: block;
      height: 85px;
      padding: 10px;
    }
    select{
      background-color: @input-bg-color;
    }
    input[type=text],input[type=password],input[type=number],input[type=email],select{
      height: 40px;
      padding: 0 10px;
    }
    p.desc{
      font-size: 14px;
      color: @light-black;
    }
    .radio-label{
      display: inline-flex;
      align-items: center;
      font-size: 14px;
      color: @black;
      cursor: pointer;
      input[type=radio],input[type=checkbox]{
        width: 16px;
        margin-right: 5px;
      }
      & + .radio-label{
        margin-left: 50px;
      }
    }
  }
  & + .form-msg{
    margin-top: -5px;
    font-size: 14px;
    line-height: 1.2;
    margin-bottom: 20px;
    color: @gray;
    padding-left: 120px;
  }
}

.load-more{
  font-size: 14px;
  height: 30px;
  line-height: 30px;
  margin-top: 95px;
  display: block;
  text-align: center;
  color: @purple;
  cursor: pointer;
}

.no-data{
  .load-more();
  color: @gray;
  cursor: default;
}

.bread-nav{
  font-size: 14px;
  display: flex;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 30px;
  &__item{
    display: inline-flex;
    align-items: center;
    color: @gray;
    .text{
      max-width: 120px;
      .text-overflow;
    }
    &:hover{
      color: @black;
    }
    & + &{
      &:before{
        content: '>';
        display: inline-block;
        padding-left: 10px;
        padding-right: 10px;
        cursor: default;
      }
    }
  }
}


//列表页码
.pagination-box {
  color: @gray;
  margin-bottom: 20px;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  .pagination-btn {
    color: @gray;
    font-size: 14px;
  }
  .prev {
    margin-right: 18px;
  }
  .next {
    margin-left: 18px;
    margin-right: 8px;
  }
  .page-count {
    font-size: 14px;
    > .input {
      width: 34px;
      height: 24px;
      border-radius: 4px;
      border: solid 1px @border;
      padding: 0 4px;
      margin-right: 6px;
      margin-left: 9px;
    }
    > .input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none !important;
    }
    > .input[type='number'] {
      -moz-appearance: textfield;
    }
    > .confirm {
      margin-left: 12px;
      color: @red;
      font-size: 14px;
      display: inline-block;
      padding: 0 8px;
      border-radius: 4px;
      border: 1px solid @border;
    }
  }
  .n-list {
    .n {
      color: @light-black;
      font-size: 16px;
      margin-right: 14px;
      width: 32px;
      height: 32px;
      &:last-child {
        margin-right: 0;
      }
      &.current {
        color: #fff;
        background-color: @blue;
      }
      &:hover {
        color: @blue;
      }
    }
  }
}

.content-block{
  margin-top: 120px;
  margin-bottom: 120px;
  &__header{
    text-align: center;
    margin-bottom: 60px;
    &-icon{
      font-size: 0;
      margin-bottom: 15px;
    }
    &-title{
      font-size: 32px;
      color: @black;
      position: relative;
      &:after{
        content: '';
        position: absolute;
        left: 50%;
        bottom: -15px;
        transform: translateX(-17px);
        width: 34px;
        height: 2px;
        background-color: @yellow;
      }
    }
  }
  &__content{

  }
}

.page{
  padding-top: @header_height;
  padding-bottom: 150px;
}

.data-panel-list{
  display: flex;
  align-items: center;
  >li,.item{
    .number{
      font-size: 36px;
      font-weight: bold;
      white-space: nowrap;
    }
    .desc{
      font-size: 18px;
    }
  }
}


.panel{
  padding-left: 30px;
  padding-right: 30px;
  background-color: #fff;
  & + .panel{
    margin-top: 10px;
  }
}

.column-panel{
  display: flex;
  align-items: flex-start;
  margin-top: 20px;
  .column-left{
    flex-shrink: 0;
    width: 850px;
    margin-right: 30px;
  }
  .column-right{
    flex-grow: 1;
  }
}

.title-underline-box{
  display: flex;
  align-items: center;
  border-bottom: 1px solid @border;
  justify-content: space-between;
  .title-underline{
    font-size: 20px;
    padding: 15px 0 10px;
    color: @light-black;
    height: 58px;
    &.active{
      color: @black;
      .active-item;
    }
    & + .title-underline{
      margin-left: 140px;
    }
  }
  .more{
    color: @purple;
  }
  &.tab-list{
    display: flex;
    >li{
      display: flex;
      & + li{
        margin-left: 140px;
      }
    }
  }
}

.tab-list{
  .tab-item{
    cursor: pointer;
  }
}

.button-tab{
  .tab-list{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 45px;
    >li{
      a{
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 140px;
        height: 45px;
        border-radius: 4px;
        background-color: #ededed;
        color: @light-black;
      }
      &.active{
        a{
          background-color: @yellow;
          color: #fff;
        }
      }
      & + li{
        margin-left: 110px;
      }
    }

  }
}

.activity-list{
  >li{
    &:hover{
      a{
        .title{
          color: @purple;
        }
      }
    }
    a{
      .item(210px, 147px, 25px);
      .img-scale;
      padding: 30px 0;
      border-bottom: 1px solid @border;
      .content{
        padding-top: 5px;
        .title{
          color: @black;
          font-size: 18px;
          margin-bottom: 12px;
          .text-overflow;
        }
        .time{
          color: @gray;
          font-size: 14px;
          margin-bottom: 17px;
        }
        .desc{
          color: @light-black;
          font-size: 14px;
          .text-limit2;
        }
      }
    }
  }
}

.sidebar{
  flex-shrink: 0;
  padding-left: 20px;
  padding-right: 20px;
  background-color: #fff;
  &__list{
    >li{
      &:hover{
        a{
          .content{
            .title{
              color: @purple;
            }
          }
        }
      }
      & + li{
        border-top: 1px solid @border;
      }
      a{
        .item(100px, 70px, 15px);
        padding: 20px;
        margin-left: -20px;
        margin-right: -20px;
        .content{
          max-width: 200px;
        }
      }
    }
  }
  &__team-list{
    >li{
      a{
        .content{
          padding-top: 5px;
          .title{
            font-size: 16px;
            color: @black;
            .text-overflow;
            margin-bottom: 10px;
          }
          .desc{
            .text-overflow;
            max-width: 180px;
            color: @gray;
            font-size: 14px;
          }
        }
      }
    }
  }
  &__activity-list{
    >li{
      a{
        .content{
          .title{
            font-size: 16px;
            color: @black;
            .text-limit2;
            line-height: 1.8;
          }
        }
      }
    }
  }
}

.search-form{
  position: relative;
  width: 580px;
  height: 46px;
  margin-left: auto;
  margin-right: auto;
  box-shadow: @box_shadow;
  .search-content{
    input[type=text]{
      font-size: 16px;
      background-color: #fff;
      width: 100%;
      height: 100%;
      border-radius: 4px;
      padding-left: 10px;
      padding-right: 30px;
    }
  }
  .search-btn{
    position: absolute;
    right: 0;
    top: 0;
    width: 40px;
    height: 100%;
    .flex-center;
    cursor: pointer;
    background-color: transparent;
  }
}

.mfp-counter{
  display: none;
}
.mfp-title{
  padding-right: 0 !important;
}

.article-box{
  padding-top: 35px;
  padding-bottom: 105px;
  &__title{
    font-size: 30px;
    color: @black;
    text-align: center;
    margin-bottom: 28px;
  }
  &__meta{
    font-size: 14px;
    padding-bottom: 30px;
    border-bottom: 1px solid @border;
    color: @gray;
    display: flex;
    align-items: center;
    justify-content: center;
    .icon-desc-wrapper{
      & + .icon-desc-wrapper{
        margin-left: 125px;
      }
    }
    .btn{
      margin-left: 125px;
      height: 28px;
      padding: 0 10px;
      font-size: 14px;
    }
  }
  .article-switch{
    margin-top: 100px;
    font-size: 14px;
    >li{
      & + li{
        margin-top: 25px;
      }
      a{
        color: @light-black;
        &:hover{
          color: @purple;
        }
      }
    }
  }
}

.news-list{
  margin-left: -50px;
  margin-bottom: -50px;
  display: flex;
  flex-wrap: wrap;
  >li{
    width: 360px;
    margin-bottom: 50px;
    margin-left: 50px;
    .img-scale;
    &:hover{
      a{
        .content{
          .title{
            color: @purple;
          }
          .desc{
            color: @black;
          }
        }
      }
    }
    a{
      display: flex;
      flex-direction: column;
      box-shadow: @box_shadow;
      .img{
        .img-wrap(100%, 254px);
        position: relative;
        .tag{
          position: absolute;
          left: 0;
          top: 0;
          height: 34px;
          display: flex;
          align-items: center;
          padding-left: 10px;
          padding-right: 10px;
          opacity: 1;
          background-color: rgba(223, 186, 83, .84);
        }
      }
      .content{
        padding-left: 20px;
        padding-right: 20px;
        height: 172px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .title{
          color: @black;
          font-size: 20px;
          .text-overflow;
          margin-bottom: 15px;
        }
        .time{
          font-size: 14px;
          color: @gray;
          margin-bottom: 15px;
        }
        .desc{
          font-size: 14px;
          color: @light-black;
          line-height: 1.5;
          .text-limit2;
        }
      }
    }
  }
  &.project-list{
    >li{
      a{
        .content{
          height: 75px;
          .title{
            margin-bottom: 0;
            .text-overflow;
          }
        }
      }
    }
  }
  &.article-list{
    >li{
      a{
        .content{
          height: 110px;
          .title{
            margin-bottom: 0;
            .text-limit2;
            white-space: normal;
          }
        }
      }
    }
  }
  &.simple-content-two-line-list{
    //只有标题 且 标题有两行长度  的列表
    .content{
      justify-content: flex-start;
      padding-top: 18px;
      line-height: 1.8;
    }
  }
  &.video-list{
    >li{
      &:hover{
        .img{
          .icon{
          }
        }
      }
      .img{
        position: relative;
        &:before{
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, .4);
          z-index: 1;
        }
        .icon{
          position: absolute;
          left: 50%;
          top: 50%;
          margin-left: -14px;
          margin-top: -14px;
          z-index: 2;
        }
      }
    }
  }
}

.tab-wrapper-default{
  .tab-list{
    display: flex;
    align-items: center;
    font-size: 20px;
    color: @black;
    border-bottom: 1px solid @border;
    >li{
      display: inline-flex;
      margin-bottom: -1px;

      a{
        display: inline-flex;
        align-items: center;
        height: 64px;
        color: @black;
      }
      & + li{
        margin-left: 100px;
      }
      &.active{
        .active-item;
      }
    }
  }
}

.tab-header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid @border;
  .tab-list{
    border-bottom: 0;
  }
  .right{
    .btn{
      padding: 0 20px;
      height: 32px;
      font-size: 14px;
      border-radius: 4px;
    }
  }
}

.mfp-bottom-bar{
  .mfp-title{
    font-size: 26px;
    margin-top: 20px;
  }
  .mfp-counter{
  }
}
div.mfp-close{
  top: -50px !important;
  &:after{
    content: '';
    .icon-close-image;
  }
}
button.mfp-close{
  display: none !important;
}

.pagination-wrap{
  .flex-center;
  margin-top: 75px;
  .page-nav{
    width: 30px;
    height: 30px;
    border-radius: 4px;
    border: 1px solid @border;
    .flex-center;
    &.disabled{
      opacity: .6;
      cursor: default;
    }
  }
  .page-prev{
    margin-right: 15px;
  }
  .page-next{
    margin-left: 15px;
  }
  .number{
    padding: 0 15px;
    color: @light-black;
    &:hover{
      color: @black;
    }
    &.on{
      color: @purple;
    }
    & + .number{
      margin-left: 15px;
    }
  }
  .total-pages{
    font-size: 14px;
    color: @gray;
    margin-left: 25px;
  }
}
